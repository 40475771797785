.chatbotButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border: none;
  color: black;
  font-size: 1.5rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  outline: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chatbotButton:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.blinkingWave {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: wave-animation 1.5s infinite ease-out;
  border: 3px solid rgba(106, 17, 203, 0.5);
}

@keyframes wave-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

.chatWindow {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 30%;
  height: 50%;
  z-index: 1000;
  color: black;
}

.chatHeader {
  background-color: #a5ed05;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatTitle {
  font-size: 16px;
  margin: 0;
}

.chatBody {
  padding: 20px;
}

.chatMessage {
  margin-bottom: 15px;
}

.inputField {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.nextButton,
.submitButton {
  background: linear-gradient(135deg, #a5ed05, #c9ee78);
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.nextButton:hover,
.submitButton:hover {
  background: linear-gradient(235deg, #a5ed05, #c9ee78);
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .chatWindow {
    width: 50%;
    height: 40%;
  }
}

@media (max-width: 576px) {
  .chatWindow {
    width: 50%;
    height: 40%;
  }

  .chatHeader {
    font-size: 14px;
  }

  .chatTitle {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .chatbotButton {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .chatWindow {
    width: 90%;
    height: 40%;
    border-radius: 20px 20px 0 0;
  }
}

@media (max-width: 321px) {
  .chatbotButton {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .chatWindow {
    width: 100%;
    height: 40%;
    border-radius: 0;
  }

  .chatHeader {
    font-size: 12px;
  }

  .chatTitle {
    font-size: 12px;
  }

  .inputField {
    font-size: 14px;
  }
}
