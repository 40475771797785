/* ContactSection.module.css */

.contactSection {
  padding: 60px 0;
  background-color: #fff;
  position: relative;
  z-index: 1;
  background-image: url("https://megaone.acrothemes.com/images/contact_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding: 0 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col12 {
  width: 100%;
}

.colLg6 {
  width: 50%;
  animation: fadeInLeft 2s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* smoother timing */
}

.colLg5 {
  width: 41.6667%;
  animation: fadeInRight 2s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* smoother timing */
}

.offsetLg1 {
  margin-left: 8.3333%;
}

.section4left {
  margin-bottom: 30px;
}

.heading {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 500;
  color: #464646;
}

.mainColor {
  color: #dc3545;
}

.dBlock {
  display: block;
}

.contactDetails {
  margin-top: 20px;
}

.text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #6c757d;
  line-height: 25px;
}

.contactDetails ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.contactDetails ul li {
  margin-bottom: 10px;
  font-size: 18px;
  color: #464646;
  display: flex;
  align-items: center;
}

.contactDetails ul li svg {
  margin-right: 10px;
  color: #dc3545;
}

.socialIconsWrapper {
  margin-top: 20px;
}

.socialIconsList {
  list-style: none;
  padding: 0;
  display: flex;
}

.socialIcon {
  color: #464646;
  font-size: 20px;
  margin-right: 15px;
  transition: color 0.4s ease; /* smooth hover */
}

.socialIcon:hover {
  color: #dc3545;
}

.contactForm {
  margin-top: 30px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formControl {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #464646;
  transition: border-color 0.3s ease; /* smooth focus effect */
}

.formControl:focus {
  border-color: #dc3545;
}

.btn {
  padding: 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.4s ease; /* smooth hover */
}

.btnMedium {
  width: 100%;
}

.btnRounded {
  border-radius: 30px;
}

.btnRed {
  background-color: #dc3545;
  color: #fff;
}

.btnRed:hover {
  background-color: #c82333;
}

/* Smoother keyframe animations */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .colLg6,
  .colLg5,
  .offsetLg1 {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .heading {
    font-size: 24px;
  }

  .text,
  .contactDetails ul li {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .contactSection {
    padding: 40px 0;
  }

  .formControl {
    padding: 12px;
    font-size: 14px;
  }

  .btn {
    padding: 12px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 20px;
    text-align: center;
  }

  .socialIconsList {
    justify-content: center;
  }

  .contactForm {
    margin-top: 20px;
  }

  .text {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .btnMedium {
    width: 100%;
  }

  .socialIcon {
    font-size: 18px;
    margin-right: 10px;
  }
}
