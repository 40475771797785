
@font-face {
 
  font-family: 'Tropiline';
  src: url('../font/Tropiline-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;

}

@font-face {
 font-display: swap;
 font-family: 'DM Sans';
 font-style: normal;
 font-weight: 400;
 src: url('../font/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K4.woff2') format('woff2');
 unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
}

/* Bold weight */
@font-face {
 font-family: 'Tropiline';
 src: url('../font/Tropiline-Regular.ttf') format('truetype');
 font-weight: 700;
 font-style: normal;
}

/* Italic style */
@font-face {
 font-family: 'Tropiline';
 src: url('../font/Tropiline-Regular.ttf') format('truetype');
 font-weight: 400;
 font-style: italic;
}


.aboutSection {
 padding: 60px 0;
}



.mainContainer{
 width: 100%;
 height: 108vh;
 position: relative;
 display: flex;
 gap: 0px;
 top:5px;
}

.leftContainer{
 width: 55%;
 
 
 background-color: #f7f7fa;
 border-radius: 15px;

}

.rightContainer{
 width: 50%;
 height: 105vh;
 display: flex;
 
 border-radius: 15px;
 gap: 15px;
 flex-direction: column;
 flex-wrap: nowrap;

}


.rightLargerContainer{
  width: 100%;
  height: 60vh;
  display: flex;
 
  border-radius: 15px;
  gap: 15px;
 
 
}
.rightLargerContainerImage{
  width: 94%;
  height: 60vh;
  display: flex; 
  background-image: url('../images/about/About.png');
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  border-radius: 15px;
  margin-left: 3%;
  gap: 15px;
}
.rightSmallerContainer{
 width: 100%;
 height: 40vh;
 display: flex;
 
 border-radius: 15px;


}
.rightSmallerContainerText{
  width: 94%;
  height: 43vh;
  display: flex;
  background-color: #a5ed05;
  border-radius: 15px;
  margin-left: 6%;
  flex-direction: column;
 
 }

.rightSmallerContainerImage{
  width: 94%;
    height: 51vh;
    display: flex;
    
    background-position: center;
    border-radius: 15px;
    margin-left: 0%;
    gap: 16px;
    margin-top: -60px;
 
  background-image: url('../images/about/About1.webp');
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  border-radius: 15px;
  margin-left: 3%;
  gap: 15px;
}

/* ---------------------------Left Section ------------------------- */


.titleFont{
 font-size: 74px;
 font-display: swap;
 font-family: 'Tropiline';
 font-weight: 700;
 padding: 40px 20px 0px 30px;
}

.rightFont{
  font-size: 20px;
  font-display: swap;
  text-align: center;
  font-family: 'DM Sans';
  font-weight: 900;
  padding: 20px 20px 0px 30px;
 }
 
 .teamFont{
  font-size: 16px;
  font-display: swap;
  text-align: center;
  font-family: 'DM Sans';
  font-weight: 300;
  padding: 20px 20px 0px 30px;
 }

 .logo{
  
  background-image: url('../images/logo.png');
  font-size: 16px;
  font-display: swap;
  width: 100px;
  height: 58px;
  
  background-size: cover;
  background-position: center;
  text-align: center;
  font-family: 'DM Sans';
  font-weight: 300;
  margin-left: 35%;
  margin-top: 50px;
  border-radius: 10px;
 }

.subFont{
 font-size:22px;
 font-display: swap;
  font-family: 'DM Sans';
  font-weight: 400;
 padding: 0px 20px 20px 40px;
}

.learnMore{
 position: relative;
 display: inline-block;
 padding: 15px 30px 15px 30px;
 color: #121212;
 transition: transform .3s;
 transform-origin: 50% 0;
 transform-style: preserve-3d;
 background-color: #072032;
 border-style: solid;
 border-width: 1px;
 margin-left: 30px;
 border-color: #e7e9ec;
 border-radius: 30px 30px 30px 30px;
 color: #fff;
 font-size: 20px;
 margin-top: 20px;
  fill: #FFFFFF;
}

.learnMore:hover{
  position: relative;
  margin-top: 20px;
  display: inline-block;
  padding: 15px 30px 15px 30px;
  color: #121212;
  transition: transform .3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  background-color: #a5ed05;
  border-style: solid;
  border-width: 1px;
  font-weight: bolder;
  margin-left: 30px;
  border-color: #e7e9ec;
  border-radius: 30px 30px 30px 30px;
  color: black;
  font-size: 20px;
  fill: black;
 }








 
 @media (max-width:1025px){
    
  .aboutSection {
   padding: 60px 0;
  }
  
  
  
  .mainContainer{
    width: 100%;
    height: 198vh;
    position: relative;
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
  
  .leftContainer{
   width: 100%;
   
   
   background-color: #f7f7fa;
   border-radius: 15px;
  
  }
  
  .rightContainer{
   width: 100%;
   height: 55vh;
   display: flex;
   
   border-radius: 15px;
   gap: 15px;
   flex-direction: column;
   flex-wrap: nowrap;
  
  }
  
  
  .rightLargerContainer{
    width: 100%;
    height: 70vh;
    display: flex;
    border-radius: 15px;
    gap: 70px;
    flex-direction: column;
   
   
  }
  .rightLargerContainerImage{
          
          background-image: url('../images/about/About.png');
          width: 94%;
          height: 70vh;
          display: flex;
          background-size: cover;
          background-position: center;
          border-radius: 15px;
          margin-left: 3%;
          gap: 15px;
          flex-direction: row;
          flex-wrap: wrap;
      
  }
  .rightSmallerContainer{
   width: 100%;
   height: 40vh;
   display: flex;
   
   border-radius: 15px;
  
  
  }
  .rightSmallerContainerText{
    width: 94%;
    height: 40vh;
    display: flex;
    background-color: #a5ed05;
    border-radius: 15px;
    margin-left: 3%;
    flex-direction: column;
   
   }
  
  .rightSmallerContainerImage{
    width: 0%;
      height: 51vh;
      display: flex;
      
      background-position: center;
      border-radius: 15px;
      margin-left: 0%;
      gap: 16px;
      margin-top: -60px;
   
    background-image: url('../images/about/About1.webp');
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    border-radius: 15px;
    margin-left: 3%;
    gap: 15px;
  }
  
  /* ---------------------------Left Section ------------------------- */
  
  
  .titleFont{
    font-size: 55px;
    font-display: swap;
    font-family: 'Tropiline';
    font-weight: 700;
    padding: 40px 20px 0px 30px;
  }
  
  .rightFont{
    font-size: 28px;
        font-display: swap;
        text-align: center;
        font-family: 'DM Sans';
        font-weight: 700;
        padding: 10px 10px 0px 10px;
   }
   
   .teamFont{
    font-size: 14px;
    font-display: swap;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    padding: 10px 10px 0px 15px;
   }
  
   .logo{
    
    background-image: url('../images/logo.png');
    font-size: 16px;
    font-display: swap;
    width: 90px;
    height: 52px;
    background-size: cover;
    background-position: center;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    margin-left: 44%;
    margin-top: 20px;
    border-radius: 10px;
   }
  
  .subFont{
    font-size: 20px;
    font-display: swap;
    font-family: 'DM Sans';
    font-weight: 500;
    text-align: justify;
    padding: 0px 30px 20px 30px;
  }
  
  .learnMore{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #072032;
    border-style: solid;
    border-width: 1px;
    
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;
  }
  
  .learnMore:hover{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #a5ed05;
    border-style: solid;
    border-width: 1px;
    
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;

 }
   }





 
 @media (max-width:769px){
    
  .aboutSection {
   padding: 60px 0;
  }
  
  
  
  .mainContainer{
    width: 100%;
    height: 198vh;
    position: relative;
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
  
  .leftContainer{
   width: 100%;
   
   
   background-color: #f7f7fa;
   border-radius: 15px;
  
  }
  
  .rightContainer{
   width: 100%;
   height: 55vh;
   display: flex;
   
   border-radius: 15px;
   gap: 15px;
   flex-direction: column;
   flex-wrap: nowrap;
  
  }
  
  
  .rightLargerContainer{
    width: 100%;
    height: 70vh;
    display: flex;
    border-radius: 15px;
    gap: 70px;
    flex-direction: column;
   
   
  }
  .rightLargerContainerImage{
          
          background-image: url('../images/about/About.png');
          width: 94%;
          height: 70vh;
          display: flex;
          background-size: cover;
          background-position: center;
          border-radius: 15px;
          margin-left: 3%;
          gap: 15px;
          flex-direction: row;
          flex-wrap: wrap;
      
  }
  .rightSmallerContainer{
   width: 100%;
   height: 40vh;
   display: flex;
   
   border-radius: 15px;
  
  
  }
  .rightSmallerContainerText{
    width: 94%;
    height: 40vh;
    display: flex;
    background-color: #a5ed05;
    border-radius: 15px;
    margin-left: 3%;
    flex-direction: column;
   
   }
  
  .rightSmallerContainerImage{
    width: 0%;
      height: 51vh;
      display: flex;
      
      background-position: center;
      border-radius: 15px;
      margin-left: 0%;
      gap: 16px;
      margin-top: -60px;
   
    background-image: url('../images/about/About1.webp');
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    border-radius: 15px;
    margin-left: 3%;
    gap: 15px;
  }
  
  /* ---------------------------Left Section ------------------------- */
  
  
  .titleFont{
    font-size: 55px;
    font-display: swap;
    font-family: 'Tropiline';
    font-weight: 700;
    padding: 40px 20px 0px 30px;
  }
  
  .rightFont{
    font-size: 28px;
        font-display: swap;
        text-align: center;
        font-family: 'DM Sans';
        font-weight: 700;
        padding: 10px 10px 0px 10px;
   }
   
   .teamFont{
    font-size: 14px;
    font-display: swap;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    padding: 10px 10px 0px 15px;
   }
  
   .logo{
    
    background-image: url('../images/logo.png');
    font-size: 16px;
    font-display: swap;
    width: 90px;
    height: 52px;
    background-size: cover;
    background-position: center;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    margin-left: 44%;
    margin-top: 20px;
    border-radius: 10px;
   }
  
  .subFont{
    font-size: 18px;
    font-display: swap;
    font-family: 'DM Sans';
    font-weight: 500;
    text-align: justify;
    padding: 0px 30px 20px 30px;
  }
  
  .learnMore{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #072032;
    border-style: solid;
    border-width: 1px;
    
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;
  }
  
  .learnMore:hover{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #a5ed05;
    border-style: solid;
    border-width: 1px;
    
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;

 }
   }



 @media (max-width:426px){
    
  .aboutSection {
   padding: 60px 0;
  }
  
  
  
  .mainContainer{
    width: 100%;
    height: 168vh;
    position: relative;
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
  
  .leftContainer{
   width: 100%;
   
   
   background-color: #f7f7fa;
   border-radius: 15px;
  
  }
  
  .rightContainer{
   width: 100%;
   height: 55vh;
   display: flex;
   
   border-radius: 15px;
   gap: 15px;
   flex-direction: column;
   flex-wrap: nowrap;
  
  }
  
  
  .rightLargerContainer{
    width: 100%;
    height: 60vh;
    display: flex;
    border-radius: 15px;
    gap: 70px;
    flex-direction: column;
   
   
  }
  .rightLargerContainerImage{
          
          background-image: url('../images/about/About.png');
          width: 94%;
          height: 40vh;
          display: flex;
          
          background-size: cover;
          background-position: center;
          border-radius: 15px;
          margin-left: 3%;
          gap: 15px;
          flex-direction: row;
          flex-wrap: wrap;
  }
  .rightSmallerContainer{
   width: 100%;
   height: 40vh;
   display: flex;
   
   border-radius: 15px;
  
  
  }
  .rightSmallerContainerText{
    width: 94%;
    height: 33vh;
    display: flex;
    background-color: #a5ed05;
    border-radius: 15px;
    margin-left: 3%;
    flex-direction: column;
   
   }
  
  .rightSmallerContainerImage{
    width: 0%;
      height: 51vh;
      display: flex;
      
      background-position: center;
      border-radius: 15px;
      margin-left: 0%;
      gap: 16px;
      margin-top: -60px;
   
    background-image: url('../images/about/About1.webp');
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    border-radius: 15px;
    margin-left: 3%;
    gap: 15px;
  }
  
  /* ---------------------------Left Section ------------------------- */
  
  
  .titleFont{
    font-size: 55px;
    font-display: swap;
    font-family: 'Tropiline';
    font-weight: 700;
    padding: 40px 20px 0px 30px;
  }
  
  .rightFont{
    font-size: 18px;
    font-display: swap;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 900;
    padding: 10px 10px 0px 10px;
   }
   
   .teamFont{
    font-size: 12px;
    font-display: swap;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    padding: 10px 10px 0px 15px;
   }
  
   .logo{
    
    background-image: url('../images/logo.png');
    font-size: 16px;
    font-display: swap;
    width: 75px;
    height: 45px;
    background-size: cover;
    background-position: center;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    margin-left: 42%;
    margin-top: 30px;
    border-radius: 10px;
   }
  
  .subFont{
    font-size: 18px;
    font-display: swap;
    font-family: 'DM Sans';
    font-weight: 500;
    text-align: justify;
    padding: 0px 30px 20px 30px;
  }
  
  .learnMore{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #072032;
    border-style: solid;
    border-width: 1px;
    margin-left: 33%;
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;
  }
  
  .learnMore:hover{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #a5ed05;
    border-style: solid;
    border-width: 1px;
    margin-left: 33%;
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;

 }
   }

 @media (max-width:376px){
    
  .aboutSection {
   padding: 60px 0;
  }
  
  
  
  .mainContainer{
    width: 100%;
    height: 185vh;
    position: relative;
    display: flex;
    gap: 0px;
    flex-direction: column;
  }
  
  .leftContainer{
   width: 100%;
     background-color: #f7f7fa;
   border-radius: 15px;
  
  }
  
  .rightContainer{
   width: 100%;
   height: 55vh;
   display: flex;
   
   border-radius: 15px;
   gap: 15px;
   flex-direction: column;
   flex-wrap: nowrap;
  
  }
  
  
  .rightLargerContainer{
    width: 100%;
    height: 60vh;
    display: flex;
    border-radius: 15px;
    gap: 70px;
    flex-direction: column;
   
   
  }
  .rightLargerContainerImage{
          
          background-image: url('../images/about/About.png');
          width: 94%;
          height: 36vh;
          display: flex;
          
          background-size: cover;
          background-position: center;
          border-radius: 15px;
          margin-left: 3%;
          gap: 15px;
          flex-direction: row;
          flex-wrap: wrap;
  }
  .rightSmallerContainer{
   width: 100%;
   height: 40vh;
   display: flex;
   
   border-radius: 15px;
  
  
  }
  .rightSmallerContainerText{
    width: 94%;
    height: 33vh;
    display: flex;
    background-color: #a5ed05;
    border-radius: 15px;
    margin-left: 3%;
    flex-direction: column;
   
   }
  
  .rightSmallerContainerImage{
    width: 0%;
      height: 51vh;
      display: flex;
      
      background-position: center;
      border-radius: 15px;
      margin-left: 0%;
      gap: 16px;
      margin-top: -60px;
   
    background-image: url('../images/about/About1.webp');
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    border-radius: 15px;
    margin-left: 3%;
    gap: 15px;
  }
  
  /* ---------------------------Left Section ------------------------- */
  
  
  .titleFont{
    font-size: 48px;
    font-display: swap;
    font-family: 'Tropiline';
    font-weight: 700;
    padding: 40px 20px 0px 30px;
  }
  
  .rightFont{
    font-size: 18px;
    font-display: swap;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 900;
    padding: 10px 10px 0px 10px;
   }
   
   .teamFont{
    font-size: 12px;
    font-display: swap;
    text-align: center;
    font-family: 'DM Sans';
    font-weight: 300;
    padding: 10px 10px 0px 15px;
   }
  
   .logo{
    
    background-image: url('../images/logo.png');
    font-size: 16px;
          font-display: swap;
          width: 70px;
          height: 40px;
          background-size: cover;
          background-position: center;
          text-align: center;
          font-family: 'DM Sans';
          font-weight: 300;
          margin-left: 39%;
          margin-top: 30px;
          border-radius: 10px;
   }
  
  .subFont{
    font-size: 16px;
        font-display: swap;
        font-family: 'DM Sans';
        font-weight: 500;
        text-align: justify;
        padding: 0px 30px 20px 30px;
  }
  
  .learnMore{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #072032;
    border-style: solid;
    border-width: 1px;
    margin-left: 25%;
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;
  }
  
  .learnMore:hover{
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    color: #121212;
    transition: transform .3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    background-color: #a5ed05;
    border-style: solid;
    border-width: 1px;
    margin-left: 25%;
    border-color: #e7e9ec;
    border-radius: 30px 30px 30px 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 0px;
    fill: #FFFFFF;
    margin-bottom:20px;

 }
   }

   @media (max-width:345px){
  
    @font-face {
     
      font-family: 'Tropiline';
      src: url('../font/Tropiline-Regular.ttf') format('truetype');
      font-weight: 400;
      font-style: normal;
    
    }
    
    @font-face {
     font-display: swap;
     font-family: 'DM Sans';
     font-style: normal;
     font-weight: 400;
     src: url('../font/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K4.woff2') format('woff2');
     unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
    }
    
    /* Bold weight */
    @font-face {
     font-family: 'Tropiline';
     src: url('../font/Tropiline-Regular.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
    }
    
    /* Italic style */
    @font-face {
     font-family: 'Tropiline';
     src: url('../font/Tropiline-Regular.ttf') format('truetype');
     font-weight: 400;
     font-style: italic;
    }
    
    
    .aboutSection {
     padding: 60px 0;
    }
    
    
    
    .mainContainer{
      width: 100%;
      height: 141vh;
      position: relative;
      display: flex;
      gap: 0px;
      flex-direction: column;
    }
    
    .leftContainer{
     width: 100%;
     
     
     background-color: #f7f7fa;
     border-radius: 15px;
    
    }
    
    .rightContainer{
     width: 100%;
     height: 55vh;
     display: flex;
     
     border-radius: 15px;
     gap: 15px;
     flex-direction: column;
     flex-wrap: nowrap;
    
    }
    
    
    .rightLargerContainer{
      width: 100%;
      height: 60vh;
      display: flex;
      border-radius: 15px;
      gap: 70px;
      flex-direction: column;
     
     
    }
    .rightLargerContainerImage{
              width: 94%;
            height: 30vh;
            display: flex;
            background-image: url('../images/about/About.png');
            background-size: cover;
            background-position: center;
            border-radius: 15px;
            margin-left: 3%;
            gap: 15px;
            flex-direction: row;
            flex-wrap: wrap;
    }
    .rightSmallerContainer{
     width: 100%;
     height: 40vh;
     display: flex;
     
     border-radius: 15px;
    
    
    }
    .rightSmallerContainerText{
      width: 94%;
      height: 33vh;
      display: flex;
      background-color: #a5ed05;
      border-radius: 15px;
      margin-left: 3%;
      flex-direction: column;
     
     }
    
    .rightSmallerContainerImage{
      width: 0%;
        height: 51vh;
        display: flex;
        
        background-position: center;
        border-radius: 15px;
        margin-left: 0%;
        gap: 16px;
        margin-top: -60px;
     
      background-image: url('../images/about/About1.webp');
      background-size: cover; /* Ensures the image covers the entire div */
      background-position: center; /* Centers the image */
      border-radius: 15px;
      margin-left: 3%;
      gap: 15px;
    }
    
    /* ---------------------------Left Section ------------------------- */
    
    
    .titleFont{
      font-size: 40px;
      font-display: swap;
      font-family: 'Tropiline';
      font-weight: 700;
      padding: 40px 20px 0px 30px;
    }
    
    .rightFont{
      font-size: 18px;
      font-display: swap;
      text-align: center;
      font-family: 'DM Sans';
      font-weight: 900;
      padding: 10px 10px 0px 10px;
     }
     
     .teamFont{
      font-size: 12px;
      font-display: swap;
      text-align: center;
      font-family: 'DM Sans';
      font-weight: 300;
      padding: 10px 10px 0px 15px;
     }
    
     .logo{
      
      background-image: url('../images/logo.png');
      font-size: 16px;
            font-display: swap;
            width: 70px;
            height: 40px;
            background-size: cover;
            background-position: center;
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 300;
            margin-left: 39%;
            margin-top: 9px;
            border-radius: 10px;
     }
    
    .subFont{
      font-size: 14px;
      font-display: swap;
      font-family: 'DM Sans';
      font-weight: 400;
      padding: 0px 20px 20px 20px;
    }
    
    .learnMore{
      position: relative;
      display: inline-block;
      padding: 15px 30px 15px 30px;
      color: #121212;
      transition: transform .3s;
      transform-origin: 50% 0;
      transform-style: preserve-3d;
      background-color: #072032;
      border-style: solid;
      border-width: 1px;
      margin-left: 25%;
      border-color: #e7e9ec;
      border-radius: 30px 30px 30px 30px;
      color: #fff;
      font-size: 20px;
      margin-top: 0px;
      fill: #FFFFFF;
      margin-bottom:20px;
    }
    
    .learnMore:hover{
      position: relative;
      margin-top: 20px;
      display: inline-block;
      padding: 15px 30px 15px 30px;
      color: #121212;
      transition: transform .3s;
      transform-origin: 50% 0;
      transform-style: preserve-3d;
      background-color: #a5ed05;
      border-style: solid;
      border-width: 1px;
      font-weight: bolder;
      margin-left: 30px;
      border-color: #e7e9ec;
      border-radius: 30px 30px 30px 30px;
      color: black;
      font-size: 20px;
      fill: black;
     }
     }

 @media (max-width:321px){
  
@font-face {
 
  font-family: 'Tropiline';
  src: url('../font/Tropiline-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;

}

@font-face {
 font-display: swap;
 font-family: 'DM Sans';
 font-style: normal;
 font-weight: 400;
 src: url('../font/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K4.woff2') format('woff2');
 unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD
}

/* Bold weight */
@font-face {
 font-family: 'Tropiline';
 src: url('../font/Tropiline-Regular.ttf') format('truetype');
 font-weight: 700;
 font-style: normal;
}

/* Italic style */
@font-face {
 font-family: 'Tropiline';
 src: url('../font/Tropiline-Regular.ttf') format('truetype');
 font-weight: 400;
 font-style: italic;
}


.aboutSection {
 padding: 60px 0;
}



.mainContainer{
  width: 100%;
  height: 141vh;
  position: relative;
  display: flex;
  gap: 0px;
  flex-direction: column;
}

.leftContainer{
 width: 100%;
 

 background-color: #f7f7fa;
 border-radius: 15px;

}

.rightContainer{
 width: 100%;
 height: 55vh;
 display: flex;
 
 border-radius: 15px;
 gap: 15px;
 flex-direction: column;
 flex-wrap: nowrap;

}


.rightLargerContainer{
  width: 100%;
  height: 60vh;
  display: flex;
  border-radius: 15px;
  gap: 70px;
  flex-direction: column;
 
 
}
.rightLargerContainerImage{
          width: 94%;
        height: 30vh;
        display: flex;
        background-image: url('../images/about/About.png');
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        margin-left: 3%;
        gap: 15px;
        flex-direction: row;
        flex-wrap: wrap;
}
.rightSmallerContainer{
 width: 100%;
 height: 40vh;
 display: flex;
 
 border-radius: 15px;


}
.rightSmallerContainerText{
  width: 94%;
  height: 33vh;
  display: flex;
  background-color: #a5ed05;
  border-radius: 15px;
  margin-left: 3%;
  flex-direction: column;
 
 }

.rightSmallerContainerImage{
  width: 0%;
    height: 51vh;
    display: flex;
    
    background-position: center;
    border-radius: 15px;
    margin-left: 0%;
    gap: 16px;
    margin-top: -60px;
 
  background-image: url('../images/about/About1.webp');
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  border-radius: 15px;
  margin-left: 3%;
  gap: 15px;
}

/* ---------------------------Left Section ------------------------- */


.titleFont{
  font-size: 40px;
  font-display: swap;
  font-family: 'Tropiline';
  font-weight: 700;
  padding: 40px 20px 0px 30px;
}

.rightFont{
  font-size: 18px;
  font-display: swap;
  text-align: center;
  font-family: 'DM Sans';
  font-weight: 900;
  padding: 10px 10px 0px 10px;
 }
 
 .teamFont{
  font-size: 12px;
  font-display: swap;
  text-align: center;
  font-family: 'DM Sans';
  font-weight: 300;
  padding: 10px 10px 0px 15px;
 }

 .logo{
  
  background-image: url('../images/logo.png');
  font-size: 16px;
        font-display: swap;
        width: 70px;
        height: 40px;
        background-size: cover;
        background-position: center;
        text-align: center;
        font-family: 'DM Sans';
        font-weight: 300;
        margin-left: 39%;
        margin-top: 9px;
        border-radius: 10px;
 }

.subFont{
  font-size: 14px;
  font-display: swap;
  font-family: 'DM Sans';
  font-weight: 400;
  padding: 0px 20px 20px 20px;
}

.learnMore{
  position: relative;
  display: inline-block;
  padding: 15px 30px 15px 30px;
  color: #121212;
  transition: transform .3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  background-color: #072032;
  border-style: solid;
  border-width: 1px;
  margin-left: 25%;
  border-color: #e7e9ec;
  border-radius: 30px 30px 30px 30px;
  color: #fff;
  font-size: 20px;
  margin-top: 0px;
  fill: #FFFFFF;
  margin-bottom:20px;
}

.learnMore:hover{
  position: relative;
  margin-top: 20px;
  display: inline-block;
  padding: 15px 30px 15px 30px;
  color: #121212;
  transition: transform .3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  background-color: #a5ed05;
  border-style: solid;
  border-width: 1px;
  font-weight: bolder;
  margin-left: 30px;
  border-color: #e7e9ec;
  border-radius: 30px 30px 30px 30px;
  color: black;
  font-size: 20px;
  fill: black;
 }
 }