@import url('https://fonts.googleapis.com/css2?family=Yatra+One&display=swap');

@font-face {
  font-family: 'Skyscapers';
  src: url('//2ttf.com/webfont/6IF8GLA3aiM/webfont.ttf') format('truetype');
}

/* Main Wrapper */
.imageSectionWrapper {
  position: relative;
  overflow: hidden;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 35%;
  text-align: center;
  color: white;
  z-index: 1;
  padding: 0 5%;
  left: 2%;
}

/* Heading and Subheading */
.heading {
  font-size: 25px;
  margin-bottom: 30px;
  font-family: 'Yatra One', sans-serif;
  text-align: center;
}

.subHeading {
  font-size: 50px;
  margin-bottom: 10px;
  font-family: 'Yatra One', sans-serif;
  text-align: center;
}

/* Description */
.description {
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 1.6;
}

/* Video Container */
.imageContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.backgroundVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Share Buttons */
.shareButtonsContainer {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
}

.shareButton {
  font-size: 1.5rem;
  color: #fff;
  transition: all 0.3s ease;
}

.shareButton:hover {
  border-radius: 50%;
  scale: 1.2;
  font-size: 1.7rem;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Specific Share Button Colors */
.facebook:hover {
  background-color: #3b5998;
}

.twitter:hover {
  background-color: #1da1f2;
}

.linkedin:hover {
  background-color: #0077b5;
  scale: 1.3;
}

.instagram:hover {
  background-color: #e4405f;
}

/* Dot Navigation */
.dotContainer {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
}

.dot {
  width: 3px;
  height: 12px;
  border-radius: 20%;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.activeDot {
  background-color: rgb(0, 200, 255);
  transform: scale(1.8);
}

/* Centered Buttons */
.centerButtonsContainer {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
  padding: 0 5%;
}

.learnMoreButton {
  background-color: #00c4ff;
  color: white;
  display: none; /* Consider setting to block if you want it visible */
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.learnMoreButton:hover {
  background-color: #0ceb7f;
  transform: scale(1.05);
}

/* Animations */
.slideFromBottom {
  animation: moveBottomToUp 1s cubic-bezier(0.25, 1, 0.5, 1);
}

@keyframes moveBottomToUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Scroll Down Indicator */
.scrollDown {
  margin-top: 20px;
  color: #fff;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
}

.bouncingArrow {
  animation: bounce 1.5s infinite ease-in-out;
  margin-top: 10px;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Z-Index Utility */
.zIndexTop {
  z-index: 3;
}
@media (max-width: 1025px) {
  .shareButtonsContainer,
  .dotContainer,
  .imageIndicatorContainer {
    top: auto;
    transform: none;
    flex-direction: row;
  }

  
.backgroundVideo {
  width: 100%;
  height: 100%;
  min-height: 600px;
  object-fit: cover;
}
  .shareButtonsContainer {
    right: 50%;
    bottom: 18%;
    transform: translateX(50%);
  }

  .dotContainer {
    left: 50%;
    bottom: 23%;
    transform: translateX(-50%);
  }

  .imageIndicatorContainer {
    left: 50%;
    transform: translateX(-50%);
  }

  .subHeading {
    font-size: 35px;
    margin-bottom: 10px;
    font-family: 'Yatra One', sans-serif;
    text-align: center;
  }

  .heading {
    font-weight: bolder;
    font-size: 25px;
     /* Consider adjusting visibility */
  }

  
  .shareButtonsContainer {
    right: 50%;
    bottom: 15%;
    transform: translateX(50%);
  }
  
  .dotContainer {
    left: 50%;
        bottom: 8%;
        transform: translateX(-50%);
  }
}
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .shareButtonsContainer,
  .dotContainer,
  .imageIndicatorContainer {
    top: auto;
    transform: none;
    flex-direction: row;
  }

  
.backgroundVideo {
  width: 100%;
  height: 100%;
  min-height: 600px;
  object-fit: cover;
}
  .shareButtonsContainer {
    right: 50%;
    bottom: 18%;
    transform: translateX(50%);
  }

  .dotContainer {
    left: 50%;
    bottom: 23%;
    transform: translateX(-50%);
  }

  .imageIndicatorContainer {
    left: 50%;
    transform: translateX(-50%);
  }

  .subHeading {
    font-size: 35px;
    margin-bottom: 10px;
    font-family: 'Yatra One', sans-serif;
    text-align: center;
  }

  .heading {
    font-weight: bolder;
    font-size: 25px;
     /* Consider adjusting visibility */
  }

  
  .shareButtonsContainer {
    right: 50%;
    bottom: 15%;
    transform: translateX(50%);
  }
  
  .dotContainer {
    left: 50%;
        bottom: 8%;
        transform: translateX(-50%);
  }
}

@media (max-width: 426px) {
  .subHeading {
    font-size: 27px;
    text-align: center;
    margin-left: 30%;
    margin-top: -12%;
    width: 233px;
    font-weight: 900;
    transform: translate(0.474684px, -4.34211px);
    transition: transform 0.2s ease-out;
  }

  .heading {
    font-weight: bolder;
    font-size: 25px;
    display: none; /* Consider adjusting visibility */
  }

  .backgroundVideo {
    width: 100%;
        height: 100%;
        min-height: 550px;
        object-fit: cover;
        align-items: center;
        align-content: center;
        align-self: center;
        inset-area: center;
  }

  .shareButtonsContainer,
  .dotContainer,
  .imageIndicatorContainer {
    top: auto;
    transform: none;
    flex-direction: row;
  }

  .shareButtonsContainer {
    right: 7%;
    bottom: 30%;
    transform: translateX(50%);
    flex-flow: column;
    font-size: 10px;
  }

  .learnMoreButton {
    background-color: #00c4ff;
    color: white;
    padding: 8px 16px;
    border: none;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    font-weight: bolder;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .learnMoreButton:hover {
    background-color: #0ceb7f;
    transform: scale(1.05);
    width: 112px;
    text-align: center;
    font-size: smaller;
    font-family: 'Yatra One', sans-serif;
  }

  .dotContainer {
    left: 5%;
    bottom: 50%;
    transform: translateX(-50%);
  }

  .imageIndicatorContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}


@media (max-width: 376px) {
  .subHeading {
    font-size: 27px;
        text-align: center;
        margin-left: 18%;
        margin-top: -12%;
        width: 233px;
        font-weight: 900;
        transform: translate(0.474684px, -4.34211px);
        transition: transform 0.2s ease-out;
  }

  .heading {
    font-weight: bolder;
    font-size: 25px;
    display: none; /* Consider adjusting visibility */
  }

  .backgroundVideo {
    min-width: 300px;
    width: auto;
    max-width: 425px;
    height: 473px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .shareButtonsContainer,
  .dotContainer,
  .imageIndicatorContainer {
    top: auto;
    transform: none;
    flex-direction: row;
  }

  .shareButtonsContainer {
    right: 7%;
    bottom: 30%;
    transform: translateX(50%);
    flex-flow: column;
    font-size: 10px;
  }

  .learnMoreButton {
    background-color: #00c4ff;
    color: white;
    padding: 8px 16px;
    border: none;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    font-weight: bolder;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .learnMoreButton:hover {
    background-color: #0ceb7f;
    transform: scale(1.05);
    width: 112px;
    text-align: center;
    font-size: smaller;
    font-family: 'Yatra One', sans-serif;
  }

  .dotContainer {
    left: 5%;
    bottom: 50%;
    transform: translateX(-50%);
  }

  .imageIndicatorContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}


@media (max-width: 321px) {
  .subHeading {
    font-size: 26px;
        text-align: center;
        margin-left: 21%;
        margin-top: -15%;
        width: 200px;
        font-weight: 900;
        transform: translate(0.474684px, -4.34211px);
        transition: transform 0.2s ease-out;
  }

  .heading {
    font-weight: bolder;
    font-size: 25px;
    display: none; /* Consider adjusting visibility */
  }

  .backgroundVideo {
    min-width: 300px;
    width: auto;
    max-width: 425px;
    height: 473px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .shareButtonsContainer,
  .dotContainer,
  .imageIndicatorContainer {
    top: auto;
    transform: none;
    flex-direction: row;
  }

  .shareButtonsContainer {
    right: 7%;
    bottom: 30%;
    transform: translateX(50%);
    flex-flow: column;
    font-size: 10px;
  }

  .learnMoreButton {
    background-color: #00c4ff;
    color: white;
    padding: 8px 16px;
    border: none;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    font-weight: bolder;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .learnMoreButton:hover {
    background-color: #0ceb7f;
    transform: scale(1.05);
    width: 112px;
    text-align: center;
    font-size: smaller;
    font-family: 'Yatra One', sans-serif;
  }

  .dotContainer {
    left: 5%;
    bottom: 50%;
    transform: translateX(-50%);
  }

  .imageIndicatorContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}
