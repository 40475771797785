/* Improved Responsive and Smoother Styles */
.testimonialSec {
  padding: 2vw 0;
  position: relative;
}


.rightOverlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 95%;
  height: 100%;
  background: #4e3d77;
  z-index: -1;
}

.testimonialArea {
  padding-top: 5vw;
  padding-bottom: 5vw;
}

.testimonialDetails {
  color: #ffffff;
  text-align: center;
  padding: 0 20px;
}

.heading {
  font-size: calc(1.8rem + 1.2vw);
  font-weight: bold;
  margin-bottom: 1.5em;
}

.heading span {
  color: #f8b400;
}

.text {
  font-size: calc(0.9rem + 0.3vw);
  line-height: 1.6;
  max-width: 500px;
  margin: 0 auto;
}
.item {
  position: relative; /* For positioning shapes */
  text-align: center;
  padding: 2rem;
  background: linear-gradient(45deg, #ff9a9e, #fad0c4, #fad390, #a1c4fd, #c2e9fb, #fddb92, #fbc2eb);
  background-size: 300% 300%;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin: 2vw;
  overflow: hidden; /* Keeps shapes inside container */
  animation: smootherGradient 15s ease infinite;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

@keyframes smootherGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Adding 3D-like animated shapes with smaller sizes */
.shape-circle,
.shape-triangle,
.shape-square {
  position: absolute;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  transform-origin: center;
  animation: randomMove 20s infinite ease-in-out;
}

/* Circle Shape */
.shape-circle {
  width: 5px; /* Reduced size */
  height: 5px; /* Reduced size */
  background: #ff5733; /* Brighter color */
  border-radius: 50%;
  top: 10%;
  left: 15%;
  animation-delay: 1s;
}

/* Triangle Shape */
.shape-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent; /* Reduced size */
  border-right: 5px solid transparent; /* Reduced size */
  border-bottom: 10px solid #33ff57; /* Brighter green color */
  top: 30%;
  left: 70%;
  animation-delay: 3s;
}

/* Square Shape */
.shape-square {
  width: 5px; /* Reduced size */
  height: 5px; /* Reduced size */
  background: #3385ff; /* Brighter blue color */
  top: 60%;
  left: 25%;
  animation-delay: 5s;
}

@keyframes randomMove {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(10px, -15px) rotate(30deg) scale(1.1);
  }
  40% {
    transform: translate(-15px, 10px) rotate(60deg) scale(0.9);
  }
  60% {
    transform: translate(15px, -10px) rotate(90deg) scale(1.2);
  }
  80% {
    transform: translate(-10px, 15px) rotate(120deg) scale(1);
  }
  100% {
    transform: translate(0, 0) rotate(180deg) scale(1);
  }
}


.iconHolder {
  font-size: calc(1.5rem + 0.5vw);
  color: #f8b400;

  margin-bottom: 1rem;
}

.testimonialText {
  font-size: calc(0.9rem + 0.3vw);
  line-height: 1.6;
  margin-bottom: 1.5em;
}
.imgHolder {
  width: 120px; /* You can adjust this as per your design */
  aspect-ratio: 1 / 1; /* Ensures the width and height are always equal */
  margin: 0 auto;
  border-radius: 50%; /* Makes it circular */
  overflow: hidden;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; /* Optional background */
}

.imgHolder img {
  width: 100%; /* Ensures the image covers the entire holder */
  height: 100%; /* Matches the holder’s aspect ratio */
  object-fit: contain; /* Ensures the entire image is visible */
  transition: transform 0.3s ease-in-out;
}

.imgHolder:hover img {
  transform: scale(1.1); /* Slight zoom on hover */
}


.userName {
  font-size: calc(1rem + 0.2vw);
  font-weight: bold;
}

@media (max-width: 768px) {
  .item {
    padding: 1.5rem;
  }

  .testimonialDetails {
    padding: 0 10px;
  }

  .imgHolder {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.8rem;
  }

  .testimonialSec {
    padding: 2vw 0;
    position: relative;
    background-color: #4e3d77;
  }

  
.heading {
  font-size: calc(1.8rem + 1.2vw);
  font-weight: bold;
 margin-bottom: 5px;
}
  .text,
  .testimonialText {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .item {
    padding: 1rem;
  }

  
  .imgHolder {
    width: 100px;
    height: 100px;
  }
}
