/* CSS (Navbar.module.css) */
.logo {
  display: flex;
  align-items: center;
}

/* Add underline styling for the active link */
.activeLink {
  position: relative;
}

.activeLink::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #00c2ff;
}

.logoImage {
  height: 50px; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
}

.navbar {
  /* Add your existing navbar styles here */
}

.navbarScrolled {
  /* Add styles for the scrolled navbar state here */
}

.hamburger {
  cursor: pointer;
}

.navLinks {
  /* Styles for nav links */
}

.showMenu {
  /* Styles to show the menu in mobile view */
}

.fullScreenMenu {
  /* Styles for the fullscreen menu */
}

.menuContent {
  /* Styles for the content inside the fullscreen menu */
}

.getStartedButton {
  /* Styles for the Get Started button */
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  width: calc(100% - 40px);
  z-index: 1000;
  transition: all 0.3s ease;
}

.navbarScrolled {
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  top: 0;
  margin: 0;
  width: 100%;
}

.logo {
  font-size: 1.8rem;
  color: #00c2ff;
  font-weight: bold;
}

.navLinks {
  display: flex;
  gap: 30px;
  align-items: center;
}

.navLinks a {
  color: #333333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s;
}

.navLinks a:hover {
  color: #00c2ff;
}

.activeLink {
  position: relative;
}

.activeLink::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: #00c2ff;
}

.getStartedButton {
  background-color: #28a745;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.getStartedButton:hover {
  background-color: #218838;
}

.hamburger {
  display: none;
  font-size: 1.8rem;
  color: #333333;
  cursor: pointer;
}


@media (max-width: 769px) {
  .navLinks {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .getStartedButton {
    display: none;
  }
}
.fullScreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.95);
  z-index: 999; /* Ensure it's above other elements */
  display: flex;
  align-items: center;
 
  justify-content: center;
}

.hamburger {
  position: fixed;
  left: 87%;
  z-index: 1000;
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
}

.menuContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.menuLink {
  color: white;
  font-size: 2rem;
  text-decoration: none;
}

.menuLink:hover {
  color: #00c4ff;
}


/* Mobile Styles */
@media (max-width: 425px) {
  /* .navLinks {
    
  } */

  .hamburger {
    
    
    position: fixed;
    left: 87%;
    z-index: 1000;
    font-size: 1.5rem;
    color: black;
    cursor: pointer;
  
  }

  .navbar{
    display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2px 6px;
        background-color: #ffffff;
        border-radius: 50px;
        box-shadow: 6px 4px 8px rgba(0, 0, 0, 0.1);
        margin: 5px 3% 10px 3%;
        position: fixed;
        top: 10px;
        left: 0px;
        right: 0;
        width: 94%;
        z-index: 1000;
        transition: all 0.3s ease;

  }

  .logoImage{
    height: 50px;
    width: 72px;
    padding: 10px;
  }
  
.navbarScrolled {
  background-color: #ffffff;
        border-radius: 0;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        top: 0;
        margin: 0;
        padding: 10px;
        max-width: 420px;
        width: 100%;
}

  .fullScreenMenu {
    display: flex;
  }

  
}
