/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');

/* Main FAQ Section Styles */
.faqSection {
    display: flex; /* Flexbox for layout */
    flex-direction: row; /* Row layout */
    transition: all 0.6s ease; /* Smooth transition for all properties */
}

/* FAQ Card Styles */
.faqscard {
    width: 40%; /* Width of the card */
    padding: 9% 4% 4% 4%; /* Padding around the card */
    height: 100vh; /* Full viewport height */
    background-color: #31511E; /* Background color */
    text-align: right; /* Text alignment */
    color: white; /* Text color */
    transition: all 0.6s ease; /* Smooth transition for all properties */
}

/* Navigation Button Styles */
.nav {
  border-radius: 25px;
  padding: 10px 68px 10px 30px;
  position: relative;
  left: 5%;
    width: 50%;
    top: -5%;
  color: white;
  background-color: #31511e;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: bolder;
  text-align: center;
  align-content: center;
  transition: all 0.6s ease;
  animation: bounce 2s infinite;
}

/* Background Shape Styles */
.faqsshape2 {
    position: absolute;
    width: 119px;
    height: 155px;
    z-index: 100;
    aspect-ratio: 1/1;
    left: 31%;
    margin-top: 85px;
    background-image: url('../images/svg/shape1.svg');
    animation: moveShape1 4s ease-in-out infinite;
}

.faqsshape1 {
    position: absolute;
    width: 63px;
    height: 93px;
    z-index: 100;
    aspect-ratio: 1 / 1;
    left: 28%;
    margin-top: 35px;
    background-image: url('../images/svg/shape2.svg');
    transition: all 0.6s ease;
}

/* FAQ Card Layout Styles */
.faqscardlayout {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    align-items: center;
    align-self: center;
    align-content: center;
    background-color: #ffffff;
    text-align: right;
    color: white;
    transition: all 0.6s ease;
}

.faqscardblock {
  width: 86%;
  height: 13%;
  border-radius: 15px;
  align-items: center;
  align-self: center;
  align-content: center;
  background-color: #F6FCDF;
  text-align: center;
  color: white;
  margin: 3% 0% 0% 6%;
  transition: all 0.6s ease;
}

.faqscardblockheading {
  font-size: 18px;
  align-items: center;
  align-self: center;
  background-color: #F6FCDF;
  text-align: left;
  color: black;
  font-weight: bolder;
  margin: 0% 0% 0% 5%;
  font-family: 'Outfit';
  transition: all 0.6s ease;
}
.faqscardblockbody {
  font-size: 14px;
  align-items: center;
  align-self: center;
  background-color: #F6FCDF;
  text-align: left;
  color: black;
  margin: 0% 0% 0% 5%;
  font-family: 'Outfit';
  transition: all 0.6s ease;
}

/* FAQ Questions Container Styles */
.faqsquestions {
    width: 60%;
    max-height: 100vh;
    overflow-y: auto;
    background-color: #859F3D;
    text-align: right;
    color: white;
    transition: all 0.6s ease;
}

/* Custom Scrollbar Styles */
.faqsquestions::-webkit-scrollbar {
    width: 4px;
}

.faqsquestions::-webkit-scrollbar-track {
    background: #ffffff;
}

.faqsquestions::-webkit-scrollbar-thumb {
    background: #31511E;
    border-radius: 10px;
}

.faqsquestions::-webkit-scrollbar-thumb:hover {
    background: #2575fc;
}

/* Gradient Background Animation */
.gradientBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #31511E, #2575fc);
    z-index: -1;
    animation: gradientAnimation 15s ease infinite;
    transition: all 0.6s ease;
}

/* Keyframes for Gradient Animation */
@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Card Text Styles */
.cardtext {
  color: black;
  position: relative;
  font-size: 60px;
  top: -10px;
  font-family: 'Outfit', sans-serif;
  font-weight: bolder;
  text-align: left;
  margin-left: 35px;
  line-height: 66px;
}

/* Heading Styles */
.heading {
    text-align: left;
    font-size: 50px;
    margin-bottom: -25px;
    font-weight: bold;
    padding: 44px;
    transition: all 0.6s ease;
}

/* FAQ List Styles */
.faqList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 800px;
    text-align: left;
    margin: 0 auto;
    transition: all 0.6s ease;
}

/* FAQ Item Styles */
.faqItem {
    padding: 15px;
    width: calc(100%);
    cursor: pointer;
    transition: transform 0.6s ease, background-color 0.6s ease;
}

.faqItem:hover {
    transform: translateY(2px);
    background-color: rgba(255, 255, 255, 0.1);
}

/* FAQ Question Styles */
.faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    transition: all 0.6s ease;
}

/* FAQ Answer Styles */
.faqAnswer {
    padding: 10px 0;
    color: #ffffff;
    font-size: 16px;
    transition: all 0.6s ease;
}

/* Icon Styles */
.icon {
    font-size: 1.5rem;
    color: #fff;
    transition: transform 0.6s ease, color 0.6s ease;
}

.faqQuestion:hover .icon {
    color: #aaa;
}

/* Filter Buttons Styles */
.filterButtons {
    display: flex;
    justify-content: left;
    gap: 10px;
    margin: 10px 42px;
}

.filterButtons button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #31511E;
    color: white;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}

.filterButtons .activeButton {
    background-color: #859F3D;
    color: #31511E;
    border: 2px solid black;
}

.filterButtons button:hover {
    background-color: #859F3D;
    color: white;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}

.linkp{
    color: #FF10F0;
    text-decoration: none;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}
.linkp:hover{
    color: #de009f;
    font-size: 16px;
    text-decoration: dashed;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}

@keyframes moveShape1 {
    0% { transform: translateX(0); }
    50% { transform: translateX(10px); }
    100% { transform: translateX(0); }
}

@keyframes bounce {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
}


@media (max-width: 1025px){

    .faqSection {
        display: flex; /* Flexbox for layout */
        flex-direction: row; /* Row layout */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* FAQ Card Styles */
    .faqscard {
        width: 40%; /* Width of the card */
        padding: 9% 4% 4% 4%; /* Padding around the card */
        height: 100vh; /* Full viewport height */
        background-color: #31511E; /* Background color */
        text-align: right; /* Text alignment */
        color: white; /* Text color */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* Navigation Button Styles */
    .nav {
        border-radius: 25px;
        padding: 10px 25px 10px 25px;
        position: relative;
        left: 5%;
        width: 50%;
        top: -15%;
        color: white;
        background-color: #31511e;
        font-size: 20px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: center;
        align-content: center;
        transition: all 0.6s ease;
    }
    
    /* Background Shape Styles */
    .faqsshape2 {
        position: absolute;
        width: 90px;
        height: 117px;
        z-index: 100;
        aspect-ratio: 1 / 1;
        left: 29%;
        margin-top: 42px;
        background-size: cover;
        background-image: url('../images/svg/shape1.svg');
        transition: all 0.6s ease;
    }
    
    .faqsshape1 {
        position: absolute;
        width: 73px;
        height: 93px;
        z-index: 100;
        aspect-ratio: 1 / 1;
        left: 18%;
        transform: rotate(271deg);
        margin-top: -40px;
        background-size: cover;
        background-image: url('../images/svg/shape2.svg');
        transition: all 0.6s ease;
    }
    
    /* FAQ Card Layout Styles */
    .faqscardlayout {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        align-items: center;
        align-self: center;
        align-content: center;
        background-color: #ffffff;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    .faqscardblock {
      width: 86%;
      height: 13%;
      border-radius: 15px;
      align-items: center;
      align-self: center;
      align-content: center;
      background-color: #F6FCDF;
      text-align: center;
      color: white;
      margin: 3% 0% 0% 6%;
      transition: all 0.6s ease;
    }
    
    .faqscardblockheading {
      font-size: 18px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      font-weight: bolder;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    .faqscardblockbody {
      font-size: 14px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    
    /* FAQ Questions Container Styles */
    .faqsquestions {
        width: 60%;
        max-height: 100vh;
        overflow-y: auto;
        background-color: #859F3D;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    /* Custom Scrollbar Styles */
    .faqsquestions::-webkit-scrollbar {
        width: 4px;
    }
    
    .faqsquestions::-webkit-scrollbar-track {
        background: #ffffff;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb {
        background: #31511E;
        border-radius: 10px;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb:hover {
        background: #2575fc;
    }
    
    /* Gradient Background Animation */
    .gradientBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #31511E, #2575fc);
        z-index: -1;
        animation: gradientAnimation 15s ease infinite;
        transition: all 0.6s ease;
    }
    
    /* Keyframes for Gradient Animation */
    @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
    
    /* Card Text Styles */
    .cardtext {
        color: black;
        position: relative;
        font-size: 56px;
        top: -10px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: left;
        margin-left: 30px;
        line-height: 63px;
    }
    
    /* Heading Styles */
    .heading {
        text-align: left;
        font-size: 50px;
        margin-bottom: -25px;
        font-weight: bold;
        padding: 44px;
        transition: all 0.6s ease;
    }
    
    /* FAQ List Styles */
    .faqList {
        display: flex;
        justify-content: center;
        gap: 10px;
        max-width: 800px;
        margin: 0 auto;
        transition: all 0.6s ease;
        text-align: left;
    }

    h4{
        font-size: 21px;
    }
    
    /* FAQ Item Styles */
    .faqItem {
        padding: 15px;
        width: calc(100%);
        cursor: pointer;
        transition: transform 0.6s ease, background-color 0.6s ease;
    }
    
    .faqItem:hover {
        transform: translateY(2px);
        background-color: rgba(255, 255, 255, 0.1);
    }
    
    /* FAQ Question Styles */
    .faqQuestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 25px;
        transition: all 0.6s ease;
    }
    
    /* FAQ Answer Styles */
    .faqAnswer {
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        transition: all 0.6s ease;
    }
    
    /* Icon Styles */
    .icon {
        font-size: 1.5rem;
        color: #fff;
        transition: transform 0.6s ease, color 0.6s ease;
    }
    
    .faqQuestion:hover .icon {
        color: #aaa;
    }
    
    /* Filter Buttons Styles */
    .filterButtons {
        display: flex;
        gap: 10px;
        margin: 10px 42px;
        flex-direction: row;
        align-content: stretch;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    
    .filterButtons button {
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        background-color: #31511E;
        color: white;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .filterButtons .activeButton {
        background-color: #859F3D;
        color: #31511E;
        border: 2px solid black;
    }
    
    .filterButtons button:hover {
        background-color: #859F3D;
        color: white;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .linkp{
        color: #FF10F0;
        text-decoration: none;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    .linkp:hover{
        color: #de009f;
        font-size: 16px;
        text-decoration: dashed;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
}
    


@media (max-width: 769px) {

    /* Import Google Fonts */
    /* Main FAQ Section Styles */
    .faqSection {
        display: flex; /* Flexbox for layout */
        flex-direction: column; /* Row layout */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* FAQ Card Styles */
    .faqscard {
        width: 100%; /* Width of the card */
        padding: 9% 4% 4% 4%; /* Padding around the card */
        height: 75vh; /* Full viewport height */
        background-color: #31511E; /* Background color */
        text-align: right; /* Text alignment */
        color: white; /* Text color */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* Navigation Button Styles */
    .nav {
        border-radius: 32px;
        padding: 10px 25px 10px 35px;
        position: relative;
        left: 5%;
        width: 40%;
        top: -10%;
        color: white;
        background-color: #31511e;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: center;
        align-content: center;
        margin: 16px;
        transition: all 0.6s ease;
    }
    
    /* Background Shape Styles */
    .faqsshape2 {
        position: absolute;
    width: 100px;
    height: 130px;
    z-index: 100;
    aspect-ratio: 1 / 1;
    left: 75%;
    margin-top: 26px;
    background-size: cover;
    transition: all 0.6s ease;
    
        background-image: url('../images/svg/shape1.svg');
        
    }
    
    .faqsshape1 {
        position: absolute;
        width: 70px;
        height: 104px;
        z-index: 100;
        aspect-ratio: 1 / 1;
        left: 66%;
        margin-top: -44px;
        background-size: cover;
        transform: rotate(272deg);
        transition: all 0.6s ease;
            background-image: url('../images/svg/shape2.svg');
       
    }
    
    /* FAQ Card Layout Styles */
    .faqscardlayout {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        align-items: center;
        align-self: center;
        align-content: center;
        background-color: #ffffff;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    .faqscardblock {
        width: 88%;
        height: 13%;
        border-radius: 15px;
        align-items: baseline;
        align-self: center;
        background-color: #F6FCDF;
        text-align: center;
        color: white;
        display: flex;
        margin: 3% 6% 3% 6%;
        transition: all 0.6s ease;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
    }
    
    .faqscardblockheading {
      font-size: 22px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      font-weight: bolder;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    .faqscardblockbody {
      font-size: 15px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    
    /* FAQ Questions Container Styles */
    .faqsquestions {
        width: 100%;
        max-height: 100vh;
        overflow-y: auto;
        background-color: #859F3D;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    /* Custom Scrollbar Styles */
    .faqsquestions::-webkit-scrollbar {
        width: 4px;
    }
    
    .faqsquestions::-webkit-scrollbar-track {
        background: #ffffff;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb {
        background: #31511E;
        border-radius: 10px;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb:hover {
        background: #2575fc;
    }
    
    /* Gradient Background Animation */
    .gradientBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #31511E, #2575fc);
        z-index: -1;
        animation: gradientAnimation 15s ease infinite;
        transition: all 0.6s ease;
    }
    
    /* Keyframes for Gradient Animation */
    @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
    
    /* Card Text Styles */
    .cardtext {
        color: black;
        position: relative;
        font-size: 45px;
        top: -25px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: center;
        margin-left: 0px;
        line-height: 35px;
    }
    
    /* Heading Styles */
    .heading {
        text-align: left;
        font-size: 50px;
        margin-bottom: -25px;
        font-weight: bold;
        padding: 44px;
        transition: all 0.6s ease;
    }
    
    /* FAQ List Styles */
    .faqList {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        gap: 10px;
        margin: 0 auto;
        transition: all 0.6s ease;
        flex-direction: column;
        align-content: stretch;
        align-items: flex-end;
        text-align: left;
    }
    h4{
        font-size: 18.5px;
    }
    
    /* FAQ Item Styles */
    .faqItem {
        padding: 15px;
        width: calc(100%);
        cursor: pointer;
        transition: transform 0.6s ease, background-color 0.6s ease;
    }
    
    .faqItem:hover {
        transform: translateY(2px);
        background-color: rgba(255, 255, 255, 0.1);
    }
    
    /* FAQ Question Styles */
    .faqQuestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 25px;
        transition: all 0.6s ease;
    }
    
    /* FAQ Answer Styles */
    .faqAnswer {
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        transition: all 0.6s ease;
    }
    
    /* Icon Styles */
    .icon {
        font-size: 1.5rem;
        color: #fff;
        transition: transform 0.6s ease, color 0.6s ease;
    }
    
    .faqQuestion:hover .icon {
        color: #aaa;
    }
    
    /* Filter Buttons Styles */
    .filterButtons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin: 10px 42px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
    
    }
    
    .filterButtons button {
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        background-color: #31511E;
        color: white;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .filterButtons .activeButton {
        background-color: #859F3D;
        color: #31511E;
        border: 2px solid black;
    }
    
    .filterButtons button:hover {
        background-color: #859F3D;
        color: white;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .linkp{
        color: #FF10F0;
        text-decoration: none;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    .linkp:hover{
        color: #de009f;
        font-size: 16px;
        text-decoration: dashed;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    }


    
@media (max-width: 426px) {

    /* Import Google Fonts */
    /* Main FAQ Section Styles */
    .faqSection {
        display: flex; /* Flexbox for layout */
        flex-direction: column; /* Row layout */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* FAQ Card Styles */
    .faqscard {
        width: 100%; /* Width of the card */
        padding: 9% 4% 4% 4%; /* Padding around the card */
        height: 75vh; /* Full viewport height */
        background-color: #31511E; /* Background color */
        text-align: right; /* Text alignment */
        color: white; /* Text color */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* Navigation Button Styles */
    .nav {
        border-radius: 32px;
        padding: 10px 20px 10px 30px;
        position: relative;
        left: 5%;
        width: 45%;
        top: -10%;
        color: white;
        background-color: #31511e;
        font-size: 15px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: center;
        align-content: center;
        margin: 16px;
        transition: all 0.6s ease;
    }
    
    /* Background Shape Styles */
    .faqsshape2 {
        position: absolute;
        width: 77px;
        height: 100px;
        z-index: 100;
        aspect-ratio: 1 / 1;
        left: 75%;
        margin-top: 10px;
        background-size: cover;
        transition: all 0.6s ease;
    
        background-image: url('../images/svg/shape1.svg');
        
    }
    
    .faqsshape1 {
            position: absolute;
            width: 36px;
            height: 52px;
            z-index: 100;
            aspect-ratio: 1 / 1;
            left: 66%;
            margin-top: -18px;
            background-size: cover;
            transform: rotate(272deg);
            transition: all 0.6s ease;
            background-image: url('../images/svg/shape2.svg');
       
    }
    
    /* FAQ Card Layout Styles */
    .faqscardlayout {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        align-items: center;
        align-self: center;
        align-content: center;
        background-color: #ffffff;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    .faqscardblock {
        width: 86%;
        height: 13%;
        border-radius: 15px;
        align-items: baseline;
        align-self: center;
        background-color: #F6FCDF;
        text-align: center;
        color: white;
        display: flex;
        margin: 3% 0% 3% 6%;
        transition: all 0.6s ease;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
    }
    
    .faqscardblockheading {
      font-size: 17px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      font-weight: bolder;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    .faqscardblockbody {
      font-size: 12.5px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    
    /* FAQ Questions Container Styles */
    .faqsquestions {
        width: 100%;
        max-height: 100vh;
        overflow-y: auto;
        background-color: #859F3D;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    /* Custom Scrollbar Styles */
    .faqsquestions::-webkit-scrollbar {
        width: 4px;
    }
    
    .faqsquestions::-webkit-scrollbar-track {
        background: #ffffff;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb {
        background: #31511E;
        border-radius: 10px;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb:hover {
        background: #2575fc;
    }
    
    /* Gradient Background Animation */
    .gradientBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #31511E, #2575fc);
        z-index: -1;
        animation: gradientAnimation 15s ease infinite;
        transition: all 0.6s ease;
    }
    
    /* Keyframes for Gradient Animation */
    @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
    
    /* Card Text Styles */
    .cardtext {
        color: black;
        position: relative;
        font-size: 48px;
        top: -25px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: left;
        margin-left: 22px;
        line-height: 53px;
    }
    
    /* Heading Styles */
    .heading {
        text-align: left;
        font-size: 50px;
        margin-bottom: -25px;
        font-weight: bold;
        padding: 44px;
        transition: all 0.6s ease;
    }
    
    /* FAQ List Styles */
    .faqList {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        gap: 10px;
        margin: 0 auto;
        transition: all 0.6s ease;
        flex-direction: column;
        align-content: stretch;
        align-items: flex-end;
        text-align: left;
    }
    h4{
        font-size: 18.5px;
    }
    
    /* FAQ Item Styles */
    .faqItem {
        padding: 15px;
        width: calc(100%);
        cursor: pointer;
        transition: transform 0.6s ease, background-color 0.6s ease;
    }
    
    .faqItem:hover {
        transform: translateY(2px);
        background-color: rgba(255, 255, 255, 0.1);
    }
    
    /* FAQ Question Styles */
    .faqQuestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 25px;
        transition: all 0.6s ease;
    }
    
    /* FAQ Answer Styles */
    .faqAnswer {
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        transition: all 0.6s ease;
    }
    
    /* Icon Styles */
    .icon {
        font-size: 1.5rem;
        color: #fff;
        transition: transform 0.6s ease, color 0.6s ease;
    }
    
    .faqQuestion:hover .icon {
        color: #aaa;
    }
    
    /* Filter Buttons Styles */
    .filterButtons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin: 10px 42px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
    
    }
    
    .filterButtons button {
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        background-color: #31511E;
        color: white;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .filterButtons .activeButton {
        background-color: #859F3D;
        color: #31511E;
        border: 2px solid black;
    }
    
    .filterButtons button:hover {
        background-color: #859F3D;
        color: white;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .linkp{
        color: #FF10F0;
        text-decoration: none;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    .linkp:hover{
        color: #de009f;
        font-size: 16px;
        text-decoration: dashed;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    }


    
@media (max-width: 376px) {

    /* Import Google Fonts */
    /* Main FAQ Section Styles */
    .faqSection {
        display: flex; /* Flexbox for layout */
        flex-direction: column; /* Row layout */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* FAQ Card Styles */
    .faqscard {
        width: 100%; /* Width of the card */
        padding: 9% 4% 4% 4%; /* Padding around the card */
        height: 75vh; /* Full viewport height */
        background-color: #31511E; /* Background color */
        text-align: right; /* Text alignment */
        color: white; /* Text color */
        transition: all 0.6s ease; /* Smooth transition for all properties */
    }
    
    /* Navigation Button Styles */
    .nav {
        border-radius: 32px;
    padding: 10px 20px 10px 30px;
    position: relative;
    left: 0px;
        width: 50%;
        top: -10%;
    margin: 15px;
    color: white;
    background-color: #31511e;
    font-size: 15px;
    font-family: 'Outfit', sans-serif;
    font-weight: bolder;
    text-align: center;
    align-content: center;
    transition: all 0.6s ease;
    }
    
    /* Background Shape Styles */
    .faqsshape2 {
        position: absolute;
        width: 77px;
        height: 100px;
        z-index: 100;
        aspect-ratio: 1 / 1;
        left: 70%;
        margin-top: 10px;
        background-size: cover;
        transition: all 0.6s ease;
    
        background-image: url('../images/svg/shape1.svg');
        
    }
    
    .faqsshape1 {
            position: absolute;
            width: 36px;
            height: 52px;
            z-index: 100;
            aspect-ratio: 1 / 1;
            left: 66%;
            margin-top: -18px;
            background-size: cover;
            transform: rotate(272deg);
            transition: all 0.6s ease;
            background-image: url('../images/svg/shape2.svg');
       
    }
    
    /* FAQ Card Layout Styles */
    .faqscardlayout {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        align-items: center;
        align-self: center;
        align-content: center;
        background-color: #ffffff;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    .faqscardblock {
        width: 86%;
        height: 13%;
        border-radius: 15px;
        align-items: baseline;
        align-self: center;
        background-color: #F6FCDF;
        text-align: center;
        color: white;
        display: flex;
        margin: 3% 0% 3% 6%;
        transition: all 0.6s ease;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
    }
    
    .faqscardblockheading {
      font-size: 17px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      font-weight: bolder;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    .faqscardblockbody {
      font-size: 12.5px;
      align-items: center;
      align-self: center;
      background-color: #F6FCDF;
      text-align: left;
      color: black;
      margin: 0% 0% 0% 5%;
      font-family: 'Outfit';
      transition: all 0.6s ease;
    }
    
    /* FAQ Questions Container Styles */
    .faqsquestions {
        width: 100%;
        max-height: 100vh;
        overflow-y: auto;
        background-color: #859F3D;
        text-align: right;
        color: white;
        transition: all 0.6s ease;
    }
    
    /* Custom Scrollbar Styles */
    .faqsquestions::-webkit-scrollbar {
        width: 4px;
    }
    
    .faqsquestions::-webkit-scrollbar-track {
        background: #ffffff;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb {
        background: #31511E;
        border-radius: 10px;
    }
    
    .faqsquestions::-webkit-scrollbar-thumb:hover {
        background: #2575fc;
    }
    
    /* Gradient Background Animation */
    .gradientBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, #31511E, #2575fc);
        z-index: -1;
        animation: gradientAnimation 15s ease infinite;
        transition: all 0.6s ease;
    }
    
    /* Keyframes for Gradient Animation */
    @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
    
    /* Card Text Styles */
    .cardtext {
        color: black;
        position: relative;
        font-size: 48px;
        top: -25px;
        font-family: 'Outfit', sans-serif;
        font-weight: bolder;
        text-align: left;
        margin-left: 22px;
        line-height: 53px;
    }
    
    /* Heading Styles */
    .heading {
        text-align: left;
        font-size: 50px;
        margin-bottom: -25px;
        font-weight: bold;
        padding: 44px;
        transition: all 0.6s ease;
    }
    
    /* FAQ List Styles */
    .faqList {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        gap: 10px;
        margin: 0 auto;
        transition: all 0.6s ease;
        flex-direction: column;
        align-content: stretch;
        align-items: flex-end;
        text-align: left;
    }
    h4{
        font-size: 18.5px;
    }
    
    /* FAQ Item Styles */
    .faqItem {
        padding: 15px;
        width: calc(100%);
        cursor: pointer;
        transition: transform 0.6s ease, background-color 0.6s ease;
    }
    
    .faqItem:hover {
        transform: translateY(2px);
        background-color: rgba(255, 255, 255, 0.1);
    }
    
    /* FAQ Question Styles */
    .faqQuestion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 25px;
        transition: all 0.6s ease;
    }
    
    /* FAQ Answer Styles */
    .faqAnswer {
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        transition: all 0.6s ease;
    }
    
    /* Icon Styles */
    .icon {
        font-size: 1.5rem;
        color: #fff;
        transition: transform 0.6s ease, color 0.6s ease;
    }
    
    .faqQuestion:hover .icon {
        color: #aaa;
    }
    
    /* Filter Buttons Styles */
    .filterButtons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin: 10px 42px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
    
    }
    
    .filterButtons button {
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        background-color: #31511E;
        color: white;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .filterButtons .activeButton {
        background-color: #859F3D;
        color: #31511E;
        border: 2px solid black;
    }
    
    .filterButtons button:hover {
        background-color: #859F3D;
        color: white;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    .linkp{
        color: #FF10F0;
        text-decoration: none;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    .linkp:hover{
        color: #de009f;
        font-size: 16px;
        text-decoration: dashed;
        transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    }



@media (max-width: 321px) {

/* Import Google Fonts */
/* Main FAQ Section Styles */
.faqSection {
    display: flex; /* Flexbox for layout */
    flex-direction: column; /* Row layout */
    transition: all 0.6s ease; /* Smooth transition for all properties */
}

/* FAQ Card Styles */
.faqscard {
    width: 100%; /* Width of the card */
    padding: 9% 4% 4% 4%; /* Padding around the card */
    height: 75vh; /* Full viewport height */
    background-color: #31511E; /* Background color */
    text-align: right; /* Text alignment */
    color: white; /* Text color */
    transition: all 0.6s ease; /* Smooth transition for all properties */
}

/* Navigation Button Styles */
.nav {
    border-radius: 32px;
    padding: 10px 20px 10px 30px;
    position: relative;
    left: 0px;
        width: 55%;
        top: -10%;
    color: white;
    background-color: #31511e;
    font-size: 15px;
    font-family: 'Outfit', sans-serif;
    font-weight: bolder;
    text-align: center;
    align-content: center;
    transition: all 0.6s ease;
}

/* Background Shape Styles */
.faqsshape2 {
    position: absolute;
    width: 77px;
    height: 100px;
    z-index: 100;
    aspect-ratio: 1 / 1;
    left: 70%;
    margin-top: 10px;
    background-size: cover;
    transition: all 0.6s ease;

    background-image: url('../images/svg/shape1.svg');
    
}

.faqsshape1 {
        position: absolute;
        width: 36px;
        height: 52px;
        z-index: 100;
        aspect-ratio: 1 / 1;
        left: 66%;
        margin-top: -18px;
        background-size: cover;
        transform: rotate(272deg);
        transition: all 0.6s ease;
        background-image: url('../images/svg/shape2.svg');
   
}

/* FAQ Card Layout Styles */
.faqscardlayout {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    align-items: center;
    align-self: center;
    align-content: center;
    background-color: #ffffff;
    text-align: right;
    color: white;
    transition: all 0.6s ease;
}

.faqscardblock {
    width: 86%;
    height: 13%;
    border-radius: 15px;
    align-items: baseline;
    align-self: center;
    background-color: #F6FCDF;
    text-align: center;
    color: white;
    display: flex;
    margin: 3% 0% 3% 6%;
    transition: all 0.6s ease;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
}

.faqscardblockheading {
  font-size: 17px;
  align-items: center;
  align-self: center;
  background-color: #F6FCDF;
  text-align: left;
  color: black;
  font-weight: bolder;
  margin: 0% 0% 0% 5%;
  font-family: 'Outfit';
  transition: all 0.6s ease;
}
.faqscardblockbody {
  font-size: 12.5px;
  align-items: center;
  align-self: center;
  background-color: #F6FCDF;
  text-align: left;
  color: black;
  margin: 0% 0% 0% 5%;
  font-family: 'Outfit';
  transition: all 0.6s ease;
}

/* FAQ Questions Container Styles */
.faqsquestions {
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    background-color: #859F3D;
    text-align: right;
    color: white;
    transition: all 0.6s ease;
}

/* Custom Scrollbar Styles */
.faqsquestions::-webkit-scrollbar {
    width: 4px;
}

.faqsquestions::-webkit-scrollbar-track {
    background: #ffffff;
}

.faqsquestions::-webkit-scrollbar-thumb {
    background: #31511E;
    border-radius: 10px;
}

.faqsquestions::-webkit-scrollbar-thumb:hover {
    background: #2575fc;
}

/* Gradient Background Animation */
.gradientBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #31511E, #2575fc);
    z-index: -1;
    animation: gradientAnimation 15s ease infinite;
    transition: all 0.6s ease;
}

/* Keyframes for Gradient Animation */
@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Card Text Styles */
.cardtext {
    color: black;
    position: relative;
    font-size: 48px;
    top: -25px;
    font-family: 'Outfit', sans-serif;
    font-weight: bolder;
    text-align: left;
    margin-left: 22px;
    line-height: 53px;
}

/* Heading Styles */
.heading {
    text-align: left;
    font-size: 50px;
    margin-bottom: -25px;
    font-weight: bold;
    padding: 44px;
    transition: all 0.6s ease;
}

/* FAQ List Styles */
.faqList {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    gap: 10px;
    margin: 0 auto;
    transition: all 0.6s ease;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-end;
    text-align: left;
}
h4{
    font-size: 18.5px;
}

/* FAQ Item Styles */
.faqItem {
    padding: 15px;
    width: calc(100%);
    cursor: pointer;
    transition: transform 0.6s ease, background-color 0.6s ease;
}

.faqItem:hover {
    transform: translateY(2px);
    background-color: rgba(255, 255, 255, 0.1);
}

/* FAQ Question Styles */
.faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    transition: all 0.6s ease;
}

/* FAQ Answer Styles */
.faqAnswer {
    padding: 10px 0;
    color: #ffffff;
    font-size: 14px;
    transition: all 0.6s ease;
}

/* Icon Styles */
.icon {
    font-size: 1.5rem;
    color: #fff;
    transition: transform 0.6s ease, color 0.6s ease;
}

.faqQuestion:hover .icon {
    color: #aaa;
}

/* Filter Buttons Styles */
.filterButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 42px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;

}

.filterButtons button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #31511E;
    color: white;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}

.filterButtons .activeButton {
    background-color: #859F3D;
    color: #31511E;
    border: 2px solid black;
}

.filterButtons button:hover {
    background-color: #859F3D;
    color: white;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}

.linkp{
    color: #FF10F0;
    text-decoration: none;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}
.linkp:hover{
    color: #de009f;
    font-size: 16px;
    text-decoration: dashed;
    transition: background-color 0.6s ease, color 0.6s ease, border 0.6s ease;
}



















}





