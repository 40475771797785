/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');

/* Import Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');


    .insightSection {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out; /* Smooth transition for size adjustments */
    }
    
    /* Background image for main insight section */
    .mainInsight {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Fixed to cover to fit the section */
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    }
    
    /* Light overlay covering 60% of the screen */
    .overLayLight {
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4); /* 40% opacity black */
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Dark overlay covering the remaining 40% of the screen */
    .overLayDark {
        position: absolute;
        top: 0;
        left: 60%;
        width: 40%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8); /* 80% opacity black */
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Main heading style */
    .heading {
        font-family: 'Outfit', sans-serif;
        font-size: 80px; /* Desktop font size */
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding: 70px; /* Desktop padding */
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition for hover effects */
    }
    
    /* Title text style for the insight section */
    .mainTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 32px; /* Desktop font size */
        position: relative;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding-left: 70px; /* Desktop padding */
        z-index: 2;
        line-clamp: 2; /* Standard property for line-clamp */
        display: -webkit-box; /* WebKit-prefixed properties */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Subtitle text style, truncated after two lines with ellipsis */
    .mainSubTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 18px; /* Desktop font size */
        position: relative;
        font-weight: 100;
        color: white;
        text-align: left;
        padding-left: 70px; /* Desktop padding */
        z-index: 2;
        transition: color 0.3s ease-in-out; /* Smooth transition */
        line-clamp: 2; /* Standard property for line-clamp */
        display: -webkit-box; /* WebKit-prefixed properties */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Active insight section styling */
    .mainInsightActive {
        position: relative;
        width: 92%;
        height: 60%;
        top: 0%;
        z-index: 2;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Status bar containing post time, views, and share icons */
    .mainStatusBar {
        padding-left: 70px; /* Desktop padding */
        display: flex;
        flex-wrap: wrap;
        gap: 20px; /* Space between items */
        padding-top: 10px;
    }
    
    /* General paragraph styling */
    p {
        color: white;
    }
    
    /* Author text style */
    .author {
        font-size: 16px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
    }
    
    /* Icon and text styling for post time, views, and share */
    .postTime,
    .views,
    .share {
        font-size: 16px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
        position: relative;
        padding-left: 24px;
        margin-right: 20px;
        display: inline-block;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Post time icon styling (clock) */
    .postTime::before {
        content: "\f017"; /* Unicode for Font Awesome clock icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 16px; /* Icon size */
    }
    
    /* Views icon styling (line chart) */
    .views::before {
        content: "\f201"; /* Unicode for Font Awesome line chart */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 16px; /* Icon size */
    }
    
    /* Share icon styling */
    .share::before {
        content: "\f1e0"; /* Unicode for Font Awesome share icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 16px; /* Icon size */
    }
    
    /* Main button styling */
    .mainButton {
        position: relative;
        margin-top: 30px;
        font-weight: 600;
        font-size: 16px; /* Desktop font size */
        margin-left: 70px; /* Desktop margin */
        border-radius: 1px;
        color: white;
        background-color: #00CFEB; /* Button color */
        padding: 10px 20px; /* Desktop padding */
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
    }
    
    .mainButton:hover {
        background-color: #00BFD1; /* Hover color */
    }
    
    /* Right sub-insight container */
    .rightSubInsight {
        height: 80%; /* Desktop height */
        left: 5%;
        margin: 10%; /* Desktop margin */
        width: 90%; /* Desktop width */
        display: flex;
        flex-wrap: wrap;
    }
    .subInsightText{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    }
    /* Individual sub-insight */
    .subInsight {
        margin: 10px;
        width: 100%;
        display: flex;
        flex: row;
        height: 26%; /* Height of each sub-insight */
    }
    
    /* Content layout for right sub-insight */
    .rightSubInsightContent {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px; /* Space between items */
    }
    
    /* Title for each sub-insight */
    .rightSubInsightTitle {
        font-size: 18px;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        font-weight: 900;
        color: white;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Image styling for each sub-insight */
    .rightSubInsightImage {
        font-size: 20px; /* Desktop font size */
        width: 100%; /* Width of image */
        font-weight: 900;
        color: white;
        aspect-ratio: 3/2;
        display: flex;
        border: 2px solid white; /* Image border */
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    .rightSubInsightImage:hover {
        transform: scale(1.05); /* Scale on hover */
    }
    
    /* Left arrow for navigation */
    .arrowl::before {
        content: "\f104";
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        padding-right: 8px;
        font-size: 40px;
        top: 90px;
        color: white;
        margin-left: 120px;
        margin-right: 30px;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
        cursor: pointer;
    }
    
    .arrowl:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    /* Right arrow for navigation */
    .arrowr::before {
        content: "\f105"; /* Unicode for fa-angle-right */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        font-size: 40px; /* Arrow size */
        display: inline-block;
        padding-left: 8px;
        color: white;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowr:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    

/* Media Queries for Responsiveness */

@media (max-width: 1025px) {
    .insightSection {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out; /* Smooth transition for size adjustments */
    }
    
    /* Background image for main insight section */
    .mainInsight {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Fixed to cover to fit the section */
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    }
    
    /* Light overlay covering 60% of the screen */
    .overLayLight {
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4); /* 40% opacity black */
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Dark overlay covering the remaining 40% of the screen */
    .overLayDark {
        position: absolute;
        top: 0;
        left: 60%;
        width: 40%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8); /* 80% opacity black */
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Main heading style */
    .heading {
        font-family: 'Outfit', sans-serif;
        font-size: 80px; /* Desktop font size */
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding: 70px; /* Desktop padding */
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition for hover effects */
    }
    
    /* Title text style for the insight section */
    .mainTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 32px; /* Desktop font size */
        position: relative;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding-left: 70px; /* Desktop padding */
        z-index: 2;
        line-clamp: 2; /* Standard property for line-clamp */
        display: -webkit-box; /* WebKit-prefixed properties */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Subtitle text style, truncated after two lines with ellipsis */
    .mainSubTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 18px; /* Desktop font size */
        position: relative;
        font-weight: 100;
        color: white;
        text-align: left;
        padding-left: 70px; /* Desktop padding */
        z-index: 2;
        transition: color 0.3s ease-in-out; /* Smooth transition */
        line-clamp: 2; /* Standard property for line-clamp */
        display: -webkit-box; /* WebKit-prefixed properties */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Active insight section styling */
    .mainInsightActive {
        position: relative;
        width: 92%;
        height: 60%;
        top: 0%;
        z-index: 2;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Status bar containing post time, views, and share icons */
    .mainStatusBar {
        padding-left: 70px; /* Desktop padding */
        display: flex;
        flex-wrap: wrap;
        gap: 20px; /* Space between items */
        padding-top: 10px;
    }
    
    /* General paragraph styling */
    p {
        color: white;
    }
    
    /* Author text style */
    .author {
        font-size: 16px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
    }
    
    /* Icon and text styling for post time, views, and share */
    .postTime,
    .views,
    .share {
        font-size: 16px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
        position: relative;
        padding-left: 24px;
        margin-right: 20px;
        display: inline-block;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Post time icon styling (clock) */
    .postTime::before {
        content: "\f017"; /* Unicode for Font Awesome clock icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 16px; /* Icon size */
    }
    
    /* Views icon styling (line chart) */
    .views::before {
        content: "\f201"; /* Unicode for Font Awesome line chart */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 16px; /* Icon size */
    }
    
    /* Share icon styling */
    .share::before {
        content: "\f1e0"; /* Unicode for Font Awesome share icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 16px; /* Icon size */
    }
    
    /* Main button styling */
    .mainButton {
        position: relative;
        margin-top: 30px;
        font-weight: 600;
        font-size: 16px; /* Desktop font size */
        margin-left: 70px; /* Desktop margin */
        border-radius: 1px;
        color: white;
        background-color: #00CFEB; /* Button color */
        padding: 10px 20px; /* Desktop padding */
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
    }
    
    .mainButton:hover {
        background-color: #00BFD1; /* Hover color */
    }
    
    /* Right sub-insight container */
    .rightSubInsight {
        height: 80%; /* Desktop height */
        left: 5%;
        margin: 10%; /* Desktop margin */
        width: 90%; /* Desktop width */
        display: flex;
        flex-wrap: wrap;
    }
    .subInsightText{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    }
    /* Individual sub-insight */
    .subInsight {
        margin: 10px;
        width: 100%;
        display: flex;
        flex: row;
        height: 26%; /* Height of each sub-insight */
    }
    
    /* Content layout for right sub-insight */
    .rightSubInsightContent {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px; /* Space between items */
    }
    
    /* Title for each sub-insight */
    .rightSubInsightTitle {
        font-size: 18px;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        font-weight: 900;
        color: white;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Image styling for each sub-insight */
    .rightSubInsightImage {
        font-size: 20px; /* Desktop font size */
        width: 100%; /* Width of image */
        font-weight: 900;
        color: white;
        aspect-ratio: 3/2;
        display: flex;
        border: 2px solid white; /* Image border */
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    .rightSubInsightImage:hover {
        transform: scale(1.05); /* Scale on hover */
    }
    
    /* Left arrow for navigation */
    .arrowl::before {
        content: "\f104";
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        padding-right: 8px;
        font-size: 40px;
        top: 90px;
        color: white;
        margin-left: 120px;
        margin-right: 30px;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
        cursor: pointer;
    }
    
    .arrowl:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    /* Right arrow for navigation */
    .arrowr::before {
        content: "\f105"; /* Unicode for fa-angle-right */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        font-size: 40px; /* Arrow size */
        display: inline-block;
        padding-left: 8px;
        color: white;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowr:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    
}

/* Tablet devices */

@media (max-width: 769px) {
    .mainInsightSection {
        width: 100%;
        height: 140vh;
        display: flex;
        object-fit: cover;
        position: relative;
        top: 0;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out;
        flex-direction: column;
        flex-wrap: nowrap; /* Smooth transition for opacity */
    }
    .insightSection {
        width: 100%;
        height: 140vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out; /* Smooth transition for size adjustments */
    }
    
    /* Background image for main insight section */
    .mainInsight {
        width: 100%;
        height: 46%;
        object-fit: cover;
        position: absolute;
        top: 0;
        aspect-ratio: 1 / 1;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    }
    
    /* Light overlay covering 60% of the screen */
    .overLayLight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:46%;
        background-color: rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in-out;/* Smooth transition for overlay adjustments */
    }
    
    /* Dark overlay covering the remaining 40% of the screen */
    .overLayDark {
        position: relative;
        top: 46%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Main heading style */
    .heading {
        font-family: 'Outfit', sans-serif;
        font-size: 66px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition for hover effects */
    }
    
    /* Title text style for the insight section */
    .mainTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 28px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding-left: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Subtitle text style, truncated after two lines with ellipsis */
    .mainSubTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 18px;
        position: relative;
        font-weight: 100;
        color: white;
        text-align: left;
        padding: 15px;
        z-index: 2;
        transition: color 0.3s ease-in-out;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Active insight section styling */
    .mainInsightActive {
        position: relative;
        width: 100%;
        height: 30%;
        top: 10%;
        z-index: 2;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Status bar containing post time, views, and share icons */
    .mainStatusBar {
        padding-left: 15px;
        display: flex;
        flex-wrap: nowrap;
        gap: 0px;
        padding-top: 2px;
    }
    
    /* General paragraph styling */
    p {
        color: white;
        font-size: 12px;
    }
    
    /* Author text style */
    .author {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
    }
    
    /* Icon and text styling for post time, views, and share */
    .postTime,
    .views,
    .share {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
        position: relative;
        padding-left: 24px;
        margin-right: 20px;
        display: inline-block;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Post time icon styling (clock) */
    .postTime::before {
        content: "\f017"; /* Unicode for Font Awesome clock icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Views icon styling (line chart) */
    .views::before {
        content: "\f201"; /* Unicode for Font Awesome line chart */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Share icon styling */
    .share::before {
        content: "\f1e0"; /* Unicode for Font Awesome share icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Main button styling */
    .mainButton {
        position: relative;
        margin-top: 0px;
        font-weight: 600;
        font-size: 18px;
        border-radius: 16px;
        color: white;
        align-content: center;
        margin-left: 80%;
        align-items: center;
        background-color: #00CFEB;
        padding: 7px 15px 7px 15px;
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
    }
    
    .mainButton:hover {
        background-color: #00BFD1; /* Hover color */
    }
    
    /* Right sub-insight container */
    .rightSubInsight {
        height: 52%; /* Desktop height */
        left: 5%;
        margin: 3%; /* Desktop margin */
        width: 94%; /* Desktop width */
        display: flex;
        flex-wrap: wrap;
    }
    
    /* Individual sub-insight */
    .subInsight {
        margin: 10px; /* Margin for each sub-insight */
        width: 100%;
        display: flex;
        flex: row; /* Width of each sub-insight */
        height: 26%; /* Height of each sub-insight */
    }
    
    /* Content layout for right sub-insight */
    .rightSubInsightContent {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px; /* Space between items */
    }
    
    /* Title for each sub-insight */
    .rightSubInsightTitle {
        font-size: 20px; /* Desktop font size */
        display: flex;
        flex-wrap: nowrap;
        width: 100%; /* Width of title */
        font-weight: 800;
        color: white;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Image styling for each sub-insight */
    .rightSubInsightImage {
        font-size: 16px;
        width: 100%;
        height: 75%;
        font-weight: 900;
        color: white;
        background-size: auto;
        aspect-ratio: 10 / 5;
        border: 2px solid white;
        transition: transform 0.3s ease-in-out;/* Smooth transition */
    }

    .subInsightText{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    
    }
    
    .rightSubInsightImage:hover {
        transform: scale(1.05); /* Scale on hover */
    }
    
    /* Left arrow for navigation */
    .arrowl::before {
        content: "\f104"; /* Unicode for fa-angle-left */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        padding-right: 8px;
        font-size: 45px; /* Arrow size */
        top: 90px;
        color: white;
        margin-left: 360px; /* Desktop margin */
        margin-right: 30px;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowl:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    /* Right arrow for navigation */
    .arrowr::before {
        content: "\f105"; /* Unicode for fa-angle-right */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        font-size: 45px; /* Arrow size */
        display: inline-block;
        padding-left: 8px;
        color: white;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowr:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
}

@media (max-width: 426px) {
    .mainInsightSection {
        width: 100%;
        height: 140vh;
        display: flex;
        object-fit: cover;
        position: relative;
        top: 0;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out;
        flex-direction: column;
        flex-wrap: nowrap; /* Smooth transition for opacity */
    }
    .insightSection {
        width: 100%;
        height: 140vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out; /* Smooth transition for size adjustments */
    }
    
    /* Background image for main insight section */
    .mainInsight {
        width: 100%;
        height: 46%;
        object-fit: cover;
        position: absolute;
        top: 0;
        aspect-ratio: 1 / 1;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    }
    
    /* Light overlay covering 60% of the screen */
    .overLayLight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:46%;
        background-color: rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in-out;/* Smooth transition for overlay adjustments */
    }
    
    /* Dark overlay covering the remaining 40% of the screen */
    .overLayDark {
        position: relative;
        top: 46%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Main heading style */
    .heading {
        font-family: 'Outfit', sans-serif;
        font-size: 56px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition for hover effects */
    }
    
    /* Title text style for the insight section */
    .mainTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 18px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding-left: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Subtitle text style, truncated after two lines with ellipsis */
    .mainSubTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 14px;
        position: relative;
        font-weight: 100;
        color: white;
        text-align: left;
        padding: 15px;
        z-index: 2;
        transition: color 0.3s ease-in-out;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Active insight section styling */
    .mainInsightActive {
        position: relative;
        width: 100%;
        height: 30%;
        top: 10%;
        z-index: 2;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Status bar containing post time, views, and share icons */
    .mainStatusBar {
        padding-left: 15px;
        display: flex;
        flex-wrap: nowrap;
        gap: 0px;
        padding-top: 2px;
    }
    
    /* General paragraph styling */
    p {
        color: white;
        font-size: 12px;
    }
    
    /* Author text style */
    .author {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
    }
    
    /* Icon and text styling for post time, views, and share */
    .postTime,
    .views,
    .share {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
        position: relative;
        padding-left: 24px;
        margin-right: 20px;
        display: inline-block;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Post time icon styling (clock) */
    .postTime::before {
        content: "\f017"; /* Unicode for Font Awesome clock icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Views icon styling (line chart) */
    .views::before {
        content: "\f201"; /* Unicode for Font Awesome line chart */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Share icon styling */
    .share::before {
        content: "\f1e0"; /* Unicode for Font Awesome share icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Main button styling */
    .mainButton {
        position: relative;
        margin-top: 10px;
        font-weight: 600;
        font-size: 14px;
        border-radius: 16px;
        color: white;
        align-content: center;
        margin-left: 60%;
        align-items: center;
        background-color: #00CFEB;
        padding: 7px 15px 7px 15px;
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
    }
    
    .mainButton:hover {
        background-color: #00BFD1; /* Hover color */
    }
    
    /* Right sub-insight container */
    .rightSubInsight {
        height: 52%; /* Desktop height */
        left: 5%;
        margin: 3%; /* Desktop margin */
        width: 94%; /* Desktop width */
        display: flex;
        flex-wrap: wrap;
    }
    
    /* Individual sub-insight */
    .subInsight {
        margin: 10px; /* Margin for each sub-insight */
        width: 100%;
        display: flex;
        flex: row; /* Width of each sub-insight */
        height: 26%; /* Height of each sub-insight */
    }
    
    /* Content layout for right sub-insight */
    .rightSubInsightContent {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px; /* Space between items */
    }
    
    /* Title for each sub-insight */
    .rightSubInsightTitle {
        font-size: 15px; /* Desktop font size */
        display: flex;
        flex-wrap: nowrap;
        width: 100%; /* Width of title */
        font-weight: 800;
        color: white;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Image styling for each sub-insight */
    .rightSubInsightImage {
        font-size: 16px;
        width: 100%;
        height: 75%;
        font-weight: 900;
        color: white;
        background-size: auto;
        aspect-ratio: 10 / 5;
        border: 2px solid white;
        transition: transform 0.3s ease-in-out;/* Smooth transition */
    }

    .subInsightText{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    
    }
    
    .rightSubInsightImage:hover {
        transform: scale(1.05); /* Scale on hover */
    }
    
    /* Left arrow for navigation */
    .arrowl::before {
        content: "\f104"; /* Unicode for fa-angle-left */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        padding-right: 8px;
        font-size: 30px; /* Arrow size */
        top: 90px;
        color: white;
        margin-left: 65px; /* Desktop margin */
        margin-right: 30px;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowl:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    /* Right arrow for navigation */
    .arrowr::before {
        content: "\f105"; /* Unicode for fa-angle-right */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        font-size: 30px; /* Arrow size */
        display: inline-block;
        padding-left: 8px;
        color: white;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowr:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
}
@media (max-width: 376px) {
    .mainInsightSection {
        width: 100%;
        height: 140vh;
        display: flex;
        object-fit: cover;
        position: relative;
        top: 0;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out;
        flex-direction: column;
        flex-wrap: nowrap; /* Smooth transition for opacity */
    }
    .insightSection {
        width: 100%;
        height: 140vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out; /* Smooth transition for size adjustments */
    }
    
    /* Background image for main insight section */
    .mainInsight {
        width: 100%;
        height: 46%;
        object-fit: cover;
        position: absolute;
        top: 0;
        aspect-ratio: 1 / 1;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    }
    
    /* Light overlay covering 60% of the screen */
    .overLayLight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:46%;
        background-color: rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in-out;/* Smooth transition for overlay adjustments */
    }
    
    /* Dark overlay covering the remaining 40% of the screen */
    .overLayDark {
        position: relative;
        top: 46%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Main heading style */
    .heading {
        font-family: 'Outfit', sans-serif;
        font-size: 46px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition for hover effects */
    }
    
    /* Title text style for the insight section */
    .mainTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 18px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding-left: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Subtitle text style, truncated after two lines with ellipsis */
    .mainSubTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 14px;
        position: relative;
        font-weight: 100;
        color: white;
        text-align: left;
        padding: 15px;
        z-index: 2;
        transition: color 0.3s ease-in-out;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Active insight section styling */
    .mainInsightActive {
        position: relative;
        width: 100%;
        height: 30%;
        top: 10%;
        z-index: 2;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Status bar containing post time, views, and share icons */
    .mainStatusBar {
        padding-left: 15px;
        display: flex;
        flex-wrap: nowrap;
        gap: 0px;
        padding-top: 2px;
    }
    
    /* General paragraph styling */
    p {
        color: white;
        font-size: 12px;
    }
    
    /* Author text style */
    .author {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
    }
    
    /* Icon and text styling for post time, views, and share */
    .postTime,
    .views,
    .share {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
        position: relative;
        padding-left: 24px;
        margin-right: 20px;
        display: inline-block;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Post time icon styling (clock) */
    .postTime::before {
        content: "\f017"; /* Unicode for Font Awesome clock icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Views icon styling (line chart) */
    .views::before {
        content: "\f201"; /* Unicode for Font Awesome line chart */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Share icon styling */
    .share::before {
        content: "\f1e0"; /* Unicode for Font Awesome share icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Main button styling */
    .mainButton {
        position: relative;
        margin-top: 10px;
        font-weight: 600;
        font-size: 14px;
        border-radius: 16px;
        color: white;
        align-content: center;
        margin-left: 60%;
        align-items: center;
        background-color: #00CFEB;
        padding: 7px 15px 7px 15px;
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
    }
    
    .mainButton:hover {
        background-color: #00BFD1; /* Hover color */
    }
    
    /* Right sub-insight container */
    .rightSubInsight {
        height: 52%; /* Desktop height */
        left: 5%;
        margin: 3%; /* Desktop margin */
        width: 94%; /* Desktop width */
        display: flex;
        flex-wrap: wrap;
    }
    
    /* Individual sub-insight */
    .subInsight {
        margin: 10px; /* Margin for each sub-insight */
        width: 100%;
        display: flex;
        flex: row; /* Width of each sub-insight */
        height: 26%; /* Height of each sub-insight */
    }
    
    /* Content layout for right sub-insight */
    .rightSubInsightContent {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px; /* Space between items */
    }
    
    /* Title for each sub-insight */
    .rightSubInsightTitle {
        font-size: 15px; /* Desktop font size */
        display: flex;
        flex-wrap: nowrap;
        width: 100%; /* Width of title */
        font-weight: 800;
        color: white;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Image styling for each sub-insight */
    .rightSubInsightImage {
        font-size: 16px;
        width: 100%;
        height: 75%;
        font-weight: 900;
        color: white;
        background-size: auto;
        aspect-ratio: 10 / 5;
        border: 2px solid white;
        transition: transform 0.3s ease-in-out;/* Smooth transition */
    }

    .subInsightText{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    
    }
    
    .rightSubInsightImage:hover {
        transform: scale(1.05); /* Scale on hover */
    }
    
    /* Left arrow for navigation */
    .arrowl::before {
        content: "\f104"; /* Unicode for fa-angle-left */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        padding-right: 8px;
        font-size: 30px; /* Arrow size */
        top: 90px;
        color: white;
        margin-left: 65px; /* Desktop margin */
        margin-right: 30px;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowl:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    /* Right arrow for navigation */
    .arrowr::before {
        content: "\f105"; /* Unicode for fa-angle-right */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        font-size: 30px; /* Arrow size */
        display: inline-block;
        padding-left: 8px;
        color: white;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowr:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
}
@media (max-width: 321px) {
    .mainInsightSection {
        width: 100%;
        height: 140vh;
        display: flex;
        object-fit: cover;
        position: relative;
        top: 0;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out;
        flex-direction: column;
        flex-wrap: nowrap; /* Smooth transition for opacity */
    }
    .insightSection {
        width: 100%;
        height: 140vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out; /* Smooth transition for size adjustments */
    }
    
    /* Background image for main insight section */
    .mainInsight {
        width: 100%;
        height: 46%;
        object-fit: cover;
        position: absolute;
        top: 0;
        aspect-ratio: 1 / 1;
        left: 0;
        z-index: -2;
        transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
    }
    
    /* Light overlay covering 60% of the screen */
    .overLayLight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:46%;
        background-color: rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in-out;/* Smooth transition for overlay adjustments */
    }
    
    /* Dark overlay covering the remaining 40% of the screen */
    .overLayDark {
        position: relative;
        top: 46%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: -1;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.3s ease-in-out; /* Smooth transition for overlay adjustments */
    }
    
    /* Main heading style */
    .heading {
        font-family: 'Outfit', sans-serif;
        font-size: 36px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition for hover effects */
    }
    
    /* Title text style for the insight section */
    .mainTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 18px;
        display: flex;
        flex-wrap: nowrap;
        font-weight: bolder;
        color: white;
        text-align: left;
        padding-left: 15px;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Subtitle text style, truncated after two lines with ellipsis */
    .mainSubTitle {
        font-family: 'Outfit', sans-serif;
        font-size: 14px;
        position: relative;
        font-weight: 100;
        color: white;
        text-align: left;
        padding: 15px;
        z-index: 2;
        transition: color 0.3s ease-in-out;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* Active insight section styling */
    .mainInsightActive {
        position: relative;
        width: 100%;
        height: 30%;
        top: 10%;
        z-index: 2;
        transition: transform 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Status bar containing post time, views, and share icons */
    .mainStatusBar {
        padding-left: 15px;
        display: flex;
        flex-wrap: nowrap;
        gap: 0px;
        padding-top: 2px;
    }
    
    /* General paragraph styling */
    p {
        color: white;
        font-size: 12px;
    }
    
    /* Author text style */
    .author {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
    }
    
    /* Icon and text styling for post time, views, and share */
    .postTime,
    .views,
    .share {
        font-size: 12px; /* Desktop font size */
        font-weight: 700;
        font-family: 'Outfit', sans-serif;
        color: aliceblue;
        position: relative;
        padding-left: 24px;
        margin-right: 20px;
        display: inline-block;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Post time icon styling (clock) */
    .postTime::before {
        content: "\f017"; /* Unicode for Font Awesome clock icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Views icon styling (line chart) */
    .views::before {
        content: "\f201"; /* Unicode for Font Awesome line chart */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Share icon styling */
    .share::before {
        content: "\f1e0"; /* Unicode for Font Awesome share icon */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        color: aliceblue;
        font-size: 12px; /* Icon size */
    }
    
    /* Main button styling */
    .mainButton {
        position: relative;
        margin-top: 10px;
        font-weight: 600;
        font-size: 14px;
        border-radius: 16px;
        color: white;
        align-content: center;
        margin-left: 60%;
        align-items: center;
        background-color: #00CFEB;
        padding: 7px 15px 7px 15px;
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
    }
    
    .mainButton:hover {
        background-color: #00BFD1; /* Hover color */
    }
    
    /* Right sub-insight container */
    .rightSubInsight {
        height: 52%; /* Desktop height */
        left: 5%;
        margin: 3%; /* Desktop margin */
        width: 94%; /* Desktop width */
        display: flex;
        flex-wrap: wrap;
    }
    
    /* Individual sub-insight */
    .subInsight {
        margin: 10px; /* Margin for each sub-insight */
        width: 100%;
        display: flex;
        flex: row; /* Width of each sub-insight */
        height: 26%; /* Height of each sub-insight */
    }
    
    /* Content layout for right sub-insight */
    .rightSubInsightContent {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px; /* Space between items */
    }
    
    /* Title for each sub-insight */
    .rightSubInsightTitle {
        font-size: 15px; /* Desktop font size */
        display: flex;
        flex-wrap: nowrap;
        width: 100%; /* Width of title */
        font-weight: 800;
        color: white;
        transition: color 0.3s ease-in-out; /* Smooth transition */
    }
    
    /* Image styling for each sub-insight */
    .rightSubInsightImage {
        font-size: 16px;
        width: 100%;
        height: 75%;
        font-weight: 900;
        color: white;
        background-size: auto;
        aspect-ratio: 10 / 5;
        border: 2px solid white;
        transition: transform 0.3s ease-in-out;/* Smooth transition */
    }

    .subInsightText{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    
    }
    
    .rightSubInsightImage:hover {
        transform: scale(1.05); /* Scale on hover */
    }
    
    /* Left arrow for navigation */
    .arrowl::before {
        content: "\f104"; /* Unicode for fa-angle-left */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        padding-right: 8px;
        font-size: 30px; /* Arrow size */
        top: 90px;
        color: white;
        margin-left: 65px; /* Desktop margin */
        margin-right: 30px;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowl:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
    
    /* Right arrow for navigation */
    .arrowr::before {
        content: "\f105"; /* Unicode for fa-angle-right */
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        font-size: 30px; /* Arrow size */
        display: inline-block;
        padding-left: 8px;
        color: white;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
        cursor: pointer;
    }
    
    .arrowr:hover::before {
        color: #00CFEB; /* Hover color */
        transform: scale(1.1); /* Scale on hover */
    }
}