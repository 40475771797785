:root {
  --yellow-bg: rgba(255, 210, 84, 0.85);
}

.teamSection {
  padding: 40px 20px;
  background-color: var(--yellow-bg);
  text-align: center;
  overflow: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.sectionHeading {
  margin-bottom: 40px;
}

.mainFont {
  font-size: 2.4rem;
  font-weight: bold;
  color: Black;
  font-family: 'Satisfy', cursive;
}
.subFont {
  font-size: 1.8rem;
  font-weight: 500;
  color: Black;
  font-family: 'Satisfy', cursive;
}

.name {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: 'Dancing Script', cursive;
  color: Black;
}

.teamSlider {
  display: flex;
  gap: 20px;
  padding: 10px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  cursor: grab;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  transition: transform 0.3s ease-in-out;
}

.teamSlider.active {
  cursor: grabbing;
}

.teamSlider::-webkit-scrollbar {
  display: none;
}

.teamBox {
  min-width: 280px;
  background: #ffffff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.4s ease-in-out;
}

.teamBox:hover {
  
  transform: translateY(-15px) scale(1.0);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.teamImage img {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
}

.teamBox:hover .teamImage img {
  transform: scale(1.02);
}

.teamText {
  text-align: center;
}

.teamSocial ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.teamSocial a {
  color: #333;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.teamSocial a:hover {
  color: #007bff;
  transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 420px) {
  .teamBox {
    min-width: 280px;
    background: #ffffff;
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.4s ease-in-out;
  }
  
  .subFont{
    font-size: 20px;
  }
}
