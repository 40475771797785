/* Services.module.css */
.maincontainer {
  background-color: #d1e9f6;
  padding: 5% 8%;
}
.container{
  width: 100%;
}
.sectionservices {
  padding: 0;
  background-color: #f9f9f9;
}

.container {
  max-width: 100%;
  padding: 20px;
  margin: 0;
  background-color: #d1e9f6;
}

.header1 {
  text-align: center;
    font-size: 50px;
    font-family: "Poppins", sans-serif;
    color: #001f3f;
    margin-bottom: 20px;
    font-weight: bolder;
}

.header2 {
  margin: -20px 0 30px;
  color: black;
  font-size: 25px;
  font-family: system-ui;
  text-align: center;
  margin-top: 10px;
}

.servicesList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.serviceItem {
  flex: 1 1 calc(25% - 20px);
  max-width: 300px;
}

.card {
  color: #384b70;
  background: #f1d3ce;
  border-radius: 15px;
  margin: 15px;
  padding: 40px;
  width: 390px;
  min-height: 440px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.08),
    0 8px 15px rgba(0, 0, 0, 0.07);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  background-color: #fff5cd;
  transform: translateY(-10px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), 0 10px 25px rgba(0, 0, 0, 0.1),
    0 15px 40px rgba(0, 0, 0, 0.08);
}

.card:hover .icon {
  font-size: 50px;
  color: #001f3f;
  background: #e78f81;
}

.icon {
  font-size: 45px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #f6efbd;
  color: #3a6d8c;
  margin-bottom: 30px;
}

.title {
  font-size: 28px;
  font-weight: bolder;
  margin-bottom: 10px;
 font-family: "Poppins", sans-serif;
}

.description {
  font-size: 1rem;
  text-align: justify;
  line-height: 1.5;
  color: #384b70;
 font-family: "Poppins", sans-serif;
}

.card:hover .description {
  color: #001f3f;
  font-family: "Poppins", sans-serif;
}

/* Arrow Buttons */
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  z-index: 2;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ff6600;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.arrow:hover {
  transform: scale(1.2);
}

.arrowright {
  right: -20px;
}

.arrowleft {
  left: -20px;
}


.posrbtn,
.btnposl {
  position: relative;
  font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: transform 0.3s ease, color 0.3s ease;
}

.posrbtn {
  top: -250px;
  left: 1260px;
}

.btnposl {
  top: 250px;
  left: -50px;
}

.posrbtn:hover,
.btnposl:hover {
  transform: scale(1.4);
  color: #ff6600;
}


@media (max-width: 1025px) {
  .serviceItem {
    flex: 1 1 100%;
  }
  .header1 {
    font-size: 2rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .header2 {
    margin-top: 1px;
    font-size: 1.15rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .description {
    font-size: 0.9rem;
  }
  
  .card{
    color: #384b70;
    background: #f1d3ce;
    border-radius: 25px;
    margin: 5px;
    padding: 30px;
    width: 245px;
    min-height: 453px;
    max-height: 490px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.08), 0 8px 15px rgba(0, 0, 0, 0.07);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 10px;
    height: 470px;
    
  }
.posrbtn,
.btnposl {
  position: relative;
  font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

.posrbtn {
  top: -250px;
  left: 1260px;
  display: none;
}

.btnposl {
  top: 250px;
  left: -50px;
  display: none;
}

.posrbtn:hover,
.btnposl:hover {
  transform: scale(1.4);
  color: #ff6600; display: none;
}



h2 {
  margin: -20px 0 30px;
  color: white;
  font-size: 50px;
}
}

@media (max-width: 768px) {
  .serviceItem {
    flex: 1 1 100%;
  }
  .header1 {
    font-size: 2rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .header2 {
    margin-top: 1px;
    font-size: 1.15rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .description {
    font-size: 0.9rem;
  }
  
  .card{
    color: #384b70;
        background: #f1d3ce;
        border-radius: 15px;
        margin: 4px;
        padding: 30px;
        width: 290px;
        min-height: 420px;
        max-height: 460px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.08), 0 8px 15px rgba(0, 0, 0, 0.07);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        font-size: 10px;
        height: 440px;
    
  }
.posrbtn,
.btnposl {
  position: relative;
  font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

.posrbtn {
  top: -250px;
  left: 1260px;
  display: none;
}

.btnposl {
  top: 250px;
  left: -50px;
  display: none;
}

.posrbtn:hover,
.btnposl:hover {
  transform: scale(1.4);
  color: #ff6600; display: none;
}



h2 {
  margin: -20px 0 30px;
  color: white;
  font-size: 50px;
}
}

@media (max-width: 426px) {
  .serviceItem {
    flex: 1 1 100%;
  }
  .header1 {
    font-size: 2rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .header2 {
    margin-top: 1px;
    font-size: 1.15rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .description {
    font-size: 0.9rem;
  }
  
  .card{
    color: #384b70;
        background: #f1d3ce;
        border-radius: 15px;
        margin: 4px;
        padding: 30px;
        width: 310px;
        min-height: 420px;
        max-height: 460px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.08), 0 8px 15px rgba(0, 0, 0, 0.07);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        font-size: 10px;
        height: 440px;
    
  }
.posrbtn,
.btnposl {
  position: relative;
  font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

.posrbtn {
  top: -250px;
  left: 1260px;
  display: none;
}

.btnposl {
  top: 250px;
  left: -50px;
  display: none;
}

.posrbtn:hover,
.btnposl:hover {
  transform: scale(1.4);
  color: #ff6600; display: none;
}



h2 {
  margin: -20px 0 30px;
  color: white;
  font-size: 50px;
}}



@media (max-width: 375px) {
  .serviceItem {
    flex: 1 1 100%;
  }
  .header1 {
    font-size: 2rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .header2 {
    margin-top: 1px;
    font-size: 1.15rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .description {
    font-size: 0.9rem;
  }
  
  .card{
    color: #384b70;
        background: #f1d3ce;
        border-radius: 15px;
        margin: 5px;
        padding: 23px;
        width: 239px;
        min-height: 465px;
        max-height: 500px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.08), 0 8px 15px rgba(0, 0, 0, 0.07);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        font-size: 10px;
        height: 470px;
    
  }
.posrbtn,
.btnposl {
  position: relative;
  font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: transform 0.3s ease, color 0.3s ease;
}

.posrbtn {
  top: -250px;
  left: 1260px;
  display: none;
}

.btnposl {
  top: 250px;
  left: -50px;
  display: none;
}

.posrbtn:hover,
.btnposl:hover {
  transform: scale(1.4);
  color: #ff6600;
}



h2 {
  margin: -20px 0 30px;
  color: white;
  font-size: 50px;
}
}

@media (max-width: 321px) {
  .serviceItem {
    flex: 1 1 100%;
  }
  .header1 {
    font-size: 2rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .header2 {
    margin-top: 1px;
    font-size: 1.15rem;
    color: black;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .description {
    font-size: 0.9rem;
  }
  
  .card{
    color: #384b70;
        background: #f1d3ce;
        border-radius: 15px;
        margin: 2px;
        padding: 24px;
        width: 225px;
        min-height: 451px;
        max-height: 475px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.08), 0 8px 15px rgba(0, 0, 0, 0.07);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        font-size: 10px;
        height: 465px;
    
  }
.posrbtn,
.btnposl {
  position: relative;
  font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: transform 0.3s ease, color 0.3s ease;
}

.posrbtn {
  top: -250px;
  left: 1260px;
  display: none;
}

.btnposl {
  top: 250px;
  left: -50px;
  display: none;
}

.posrbtn:hover,
.btnposl:hover {
  transform: scale(1.4);
  color: #ff6600;
}



h2 {
  margin: -20px 0 30px;
  color: white;
  font-size: 50px;
}
}